import React, { useState, useEffect } from "react";
import "./Home.scss";
import stravaLogo from "../../images/pwd_strava.svg";
import infoIcon from "../../images/info_tertiary.svg";
import { WidgetWithButton, HomeWorkoutWidget } from '../v3_components/Widget';
import { HealthOverviewHomemade } from '../v3_components/Graph';
import { createDimDataForProfilev4 } from '../../utils/metrics_processing';
import { WikiFreeForm } from '../v3_components/DimensionHelpers';
import { getWorkoutSuggestions } from './utils/workouts_db';
import FullProfile from './FullProfile';
import WorkoutView from './WorkoutView';

const Home = ({ userData, isCurrentUser }) => {
  const [showFullProfile, setShowFullProfile] = useState(false);
  const [selectedWorkout, setSelectedWorkout] = useState(null);
  const [workouts, setWorkouts] = useState([]);
  const [isLoadingWorkouts, setIsLoadingWorkouts] = useState(true);
  const dimData = createDimDataForProfilev4(userData);

  useEffect(() => {
    const fetchWorkouts = async () => {
      setIsLoadingWorkouts(true);
      const workoutData = await getWorkoutSuggestions(userData);
      setWorkouts(workoutData);
      setIsLoadingWorkouts(false);
    };
    
    fetchWorkouts();
  }, [userData]);

  if (selectedWorkout) {
    return <WorkoutView 
      workout={selectedWorkout} 
      onClose={() => setSelectedWorkout(null)} 
    />;
  }

  if (showFullProfile) {
    return <FullProfile userData={userData} onClose={() => setShowFullProfile(false)} />;
  }

  if (!userData || !userData.user_info) return null;

  const { firstname, lastname, profile } = userData.user_info.core;

  return (
    <div className="home-v4">
      <div className="container">
        {/* Header */}
        <div className="profile-header">
          <div className="header-content">
            <div className="profile-info">
              {window.location.href.includes("profile") ? (
                <img src={require("../../images/usain.png")} alt="Profile" className="profile-image" />
              ) : (
                <img src={profile} alt="Profile" className="profile-image" />
              )}
              <span className="profile-name">{firstname}</span>
            </div>
          </div>
        </div>

        {/* Main Indicator */}
        <div className="years-indicator">
          <WikiFreeForm reference="good_years_left">
            <div className="main-number">

          



              <span className="number">{dimData.years.in_good_health}</span>
              <span className="label">
                years in shape
                <img src={infoIcon} alt="Info" className="info-icon" />
              </span> 
            </div>
          </WikiFreeForm>
          {/* <div className="health-prediction">
            <p>
              You'll stay in great shape until age <span className="highlight">{dimData.years.in_good_health}</span>, meaning you can comfortably climb 2 flights of stairs until then.
            </p>
          </div> */}
        </div>

        <WidgetWithButton 
          title="Health overview"
          buttonTitle="See full profile"
          buttonStyle="tertiary"
          onClick={() => setShowFullProfile(true)}
        >
          <HealthOverviewHomemade 
            cardioPercentile={dimData.percentiles.cardio}
            strengthPercentile={dimData.percentiles.strength}
            stabilityPercentile={dimData.percentiles.stability}
          />
        </WidgetWithButton>

        {/* Suggested workouts section */}
        <div className="dimension-section">
          <div className="dimension-title">
            <span>Suggested workouts</span> 
          </div>
          <div className="health-prediction">
            <p>Gain up to <span className="highlight">{dimData.years.potential_gain} great years</span> in 6 months with these strength and cardio workouts.</p>
          </div>
          {isLoadingWorkouts ? (
            <p className="loading-text">Fetching personalised workouts...</p>
          ) : (
            workouts.map((workout, index) => (
              <HomeWorkoutWidget 
                key={index}
                workoutTitle={workout.title}
                tags={workout.tags}
                description={workout.short_description}
                length={workout.time_in_mins}
                difficulty={workout.difficulty}
                onClick={() => setSelectedWorkout(workout)}
              />
            ))
          )}
        </div>

        {/* Add Strava logo at the bottom */}
        <img src={stravaLogo} alt="Powered by Strava" className="strava-logo-bottom" />
      </div>
    </div>
  );
};

export default Home; 