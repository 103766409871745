import React from 'react';
import './Widget.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import PercentileTag, { getPercentileTextPrecise } from './PercentileTag';
// import EffortIndicator from './Indicator';
import Metric from './Metric';

import { EffortIndicator } from './Indicator';
import { ConfidenceIndicator } from './Indicator';
import { PercentileBars } from './Indicator';

import cardioIcon from '../../images/cardio_icon.svg';
import strengthIcon from '../../images/strength_icon.svg';
import stabilityIcon from '../../images/stability_icon.svg';
import timerIcon from '../../images/timer.svg';

export const Widget = ({ children, className, title, icon }) => {
  return (
    <div className={`widget ${className || ''}`}>
      {title && (
        <div className="widget-title">
          <div className="title-content">
            {icon && <img src={icon} alt={`${title} icon`} className='widget-icon' />}
            <span>{title}</span>
          </div>
        </div>
      )}
      <div className="widget-content">
        {children}
      </div>
    </div>
  )
};

export const WidgetHome = ({ 
  title, 
  variant, 
  icon, 
  percentile, 
  effortBars = 0, 
  effortTitle = 'Effort' 
}) => {
  const hasNoMetric = percentile === undefined || percentile === null;

  return (
    <div className={`widget widget-home ${variant || ''} ${hasNoMetric ? 'no-metric' : ''}`}>
      <div className="widget-title">
        <div className="title-content">
          <img src={icon} alt={`${title} icon`} className='widget-icon' />
          <span>{title}</span>
        </div>
        <PercentileTag percentile={percentile} variant={variant} />
      </div>
      <div className="widget-content">
        <div className="effort-wrapper">
          <EffortIndicator 
            title={effortTitle} 
            filledBars={effortBars} 
            variant={variant} 
          />
        </div>
      </div>
      {!hasNoMetric && <FontAwesomeIcon icon={faChevronRight} className="chevron-icon-home" />}
    </div>
  );
};

export const WidgetDimension = ({ 
    title, 
    variant, 
    icon, 
    percentile, 
    metrics, 
    precision,
    effortBars,
    effortTitle
}) => {
  // If this is an effort widget (has effortBars), keep the existing layout
  if (effortBars !== undefined) {
    return (
      <div className={`widget widget-dimension ${variant || ''}`}>
        <div className="widget-title">
          <div className="title-content">
            {icon && <img src={icon} alt={`${title} icon`} className='widget-icon' />}
            <span>{title}</span>
          </div>
          {percentile && <PercentileTag percentile={percentile} variant={variant} />}
        </div>
        <div className="widget-content">
          <div className="metrics-container">
            <EffortIndicator 
              title={effortTitle} 
              filledBars={effortBars} 
              variant={variant} 
            />
            {metrics?.map((metric, index) => (
              <Metric
                key={index + 1}
                title={metric.label}
                value={metric.value}
                unit={metric.unit}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  // For measure widgets, use the new layout similar to WidgetFullProfile
  const mainMetric = metrics?.find(m => m.label !== "Confidence");
  
  return (
    <div className={`widget widget-dimension ${variant || ''}`}>
      <div className="widget-title">
        <div className="title-content">
          {icon && <img src={icon} alt={`${title} icon`} className='widget-icon' />}
          <span>{title}</span>
        </div>
      </div>
      <div className="widget-content">
        <div className="metrics-wrapper">
          <Metric
            title={mainMetric?.label}
            value={mainMetric?.value}
            unit={mainMetric?.unit}
          />
          <PercentileBars 
            percentile={percentile}
            variant={variant}
          />
          <div className="metric">
            <div className="metric-title">Confidence</div>
            <ConfidenceIndicator precision={precision} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const WidgetWithButton = ({ 
  title, 
  children, 
  buttonTitle = "See full profile",
  buttonStyle = "secondary",
  onClick 
}) => {
  return (
    <div className="widget widget-with-button">
      <div className="widget-title">
        <div className="title-content">
          <span>{title}</span>
        </div>
      </div>
      <div className="widget-content">
        {children}
      </div>
      <button 
        className={`widget-button ${buttonStyle}`} 
        onClick={onClick}
      >
        {buttonTitle}
      </button>
    </div>
  );
};

export const HomeWorkoutWidget = ({ 
  workoutTitle, 
  tags, 
  description, 
  length, 
  difficulty,
  onClick 
}) => {
  const getIconForTag = (tag) => {
    switch (tag.toLowerCase()) {
      case 'cardio': return cardioIcon;
      case 'strength': return strengthIcon;
      case 'stability': return stabilityIcon;
      default: return null;
    }
  };

  return (
    <div className="widget widget-workout">
      <div className="dimension-tags">
        {tags.map((tag, index) => (
          <span key={index} className={`workout-tag ${tag.toLowerCase()}`}>
            <img src={getIconForTag(tag)} alt="" className="tag-icon" />
            {tag.toUpperCase()}
          </span>
        ))}
      </div>
      <div className="widget-title">
        <div className="title-content">
          {workoutTitle}
        </div>
      </div>
      <div className="workout-meta">
        <div className="meta-tags">
          <span className="time-tag">
            <img src={timerIcon} alt="Duration" />
            {length} min
          </span>
          <span className="difficulty-tag">
            {difficulty.toUpperCase()}
          </span>
        </div>
      </div>
      <button 
        className="widget-button primary"
        onClick={onClick}
      >
        View workout
      </button>
    </div>
  );
};

export const WidgetFullProfile = ({ 
  title, 
  variant, 
  icon,
  mainMetric = {
    title: '',
    value: null,
    unit: ''
  },
  percentile,
  precision,
  onClick
}) => {
  console.log(`WidgetFullProfile ${title} precision:`, precision);
  const hasNoMetric = percentile === undefined || percentile === null || precision === null;

  if (hasNoMetric) {
    return (
      <div className="widget-error insufficient-data">
        <em>Insufficient data</em>
      </div>
    );
  }

  return (
    <div className={`widget widget-full-profile ${variant || ''}`} onClick={onClick}>
      <div className="widget-content">
        <div className="metrics-wrapper">
          <Metric
            title={mainMetric.title}
            value={mainMetric.value}
            unit={mainMetric.unit}
          />
          <PercentileBars 
            percentile={percentile}
            variant={variant}
          />
          <div className="metric">
            <div className="metric-title">Confidence</div>
            <ConfidenceIndicator precision={precision} />
          </div>
        </div>
        <FontAwesomeIcon icon={faChevronRight} className="chevron-icon-home" />
      </div>
    </div>
  );
};

