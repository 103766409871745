export const workout_data = {
  'default': [
    {
      "id": "default_1",
      "title": "Turn your Chalk Farm run into intervals 🚦",
      "short_description": "Add 5x 2-minute sprints to improve VO2 max and endurance. Intervals are key for cardiovascular health and longevity.",
      "full_description": <>
        <p>Transform your regular Chalk Farm run by incorporating 5 sets of 2-minute high-intensity sprints, followed by 2-minute recovery jogs. These structured intervals are scientifically proven to boost your VO2 max - the gold standard measure of cardiovascular fitness.</p>
        <p>Start with a 5-minute warm-up jog, then alternate between sprint and recovery periods. Focus on maintaining good form during the sprints, pushing to about 85% of your maximum effort. Cool down with a 5-minute easy jog to complete the session.</p>
      </>,
      "tags": ["cardio"],
      "length_in_min": 30,
      "difficulty": "hard"
    },
    {
      "id": "eseror_1",
      "title": "Add hills to your Kentish Town ride ⛰️",
      "short_description": "Boost strength and cardio by tackling hills. Few hill rides in past sessions, so this builds endurance and muscle power.",
      "full_description": <>
        <p>Transform your regular Kentish Town ride by incorporating strategic hill climbs. This workout targets both your cardiovascular system and muscular strength, addressing the lower number of hill-focused sessions in your recent rides.</p>
        <p>Find 4-5 moderate hills along your route. On each climb, maintain a steady seated position for the first half, then transition to a standing climb for the second half. Keep your cadence between 60-70 RPM during climbs. Between hills, recover with 3-5 minutes of easy spinning to prepare for the next ascent.</p>
      </>,
      "tags": ["cardio", "strength"],
      "length_in_min": 45,
      "difficulty": "hard"
    },
    {
      "id": "federico_traeger_1",
      "title": "Turn your Chalk Farm run into intervals 🏃‍♂️",
      "short_description": "Add 6x 1-minute sprints to boost VO2 max and endurance. Intervals enhance cardiovascular health and longevity.",
      "full_description": <>
        <p>Elevate your Chalk Farm run with six strategic 1-minute sprint intervals. This high-intensity interval training (HIIT) session is designed to significantly improve your VO2 max and overall cardiovascular fitness.</p>
        <p>Begin with a 10-minute easy warm-up jog. Then, perform six sets of 1-minute sprints at 90% effort, each followed by 2 minutes of easy jogging recovery. Focus on powerful arm drives and maintaining good posture during sprints. Finish with a 5-minute cool-down jog to properly recover.</p>
      </>,
      "tags": ["cardio"],
      "length_in_min": 30,
      "difficulty": "hard"
    },
    {
      "id": "9411535_1",
      "title": "Add intervals to your Gospel Oak run 🏃‍♂️",
      "short_description": "Incorporate 6x 1-minute sprints to enhance VO2 max and cardio endurance. Boost cardiovascular longevity effectively.",
      "full_description": <>
        <p>Transform your Gospel Oak run into a powerful interval training session. This workout is specifically designed to boost your cardiovascular capacity through structured high-intensity efforts.</p>
        <p>Start with an 8-minute progressive warm-up. For the main set, complete six 1-minute sprint intervals at 85-90% effort, with 2-minute jogging recoveries between each. Pay special attention to your breathing rhythm and maintain a tall posture throughout. Complete the session with a gradual 7-minute cool-down to aid recovery.</p>
      </>,
      "tags": ["cardio"],
      "length_in_min": 30,
      "difficulty": "hard"
    },
    {
      "id": "fred_speirs_1",
      "title": "Turn your virtual ride into cadence work 🚴",
      "short_description": "Focus on high cadence (90+ RPM) for 10 minutes. Builds cardio efficiency and muscular endurance for longevity.",
      "full_description": <>
        <p>Enhance your virtual cycling session with focused high-cadence intervals. This workout improves your pedaling efficiency and cardiovascular endurance while reducing joint stress through controlled, quick spinning.</p>
        <p>After a 10-minute warm-up, alternate between 2-minute blocks of high cadence (90+ RPM) and 1-minute recovery periods at normal cadence (80 RPM). Maintain moderate resistance throughout high-cadence sections - the goal is smooth, controlled spinning rather than all-out effort. Complete 5 sets before cooling down for 8 minutes.</p>
      </>,
      "tags": ["cardio", "strength"],
      "length_in_min": 35,
      "difficulty": "medium"
    },
    {
      "id": "timothe_jauffret_1",
      "title": "Turn your Can Gaio run into a Fartlek 🇸🇪",
      "short_description": "Incorporate 6x 2-minute sprints to boost VO2 max and cardio. A fun way to enhance endurance and improve longevity.",
      "full_description": <>
        <p>Convert your Can Gaio run into an engaging Fartlek session - Swedish for "speed play". This workout combines structured intervals with the freedom to respond to your terrain and energy levels.</p>
        <p>Begin with a 10-minute easy warm-up. Then, alternate between 2-minute "surge" periods at about 80% effort and 2-minute recovery jogs. Use landmarks or terrain features to guide your intervals naturally. The key is maintaining a strong but controlled pace during surge periods while allowing full recovery between efforts.</p>
      </>,
      "tags": ["cardio"],
      "length_in_min": 30,
      "difficulty": "hard"
    }
  ],
  'eseror': [
    {
      "id": "eseror_2",
      "title": "Turn your Belsize Park run into a Fartlek",
      "short_description": "Mix sprint and jog intervals to improve VO2 max and cardio. A fun way to enhance your longevity and aerobic capacity.",
      "full_description": <>
        <p>Transform your Belsize Park route into an engaging Fartlek session that combines structured and unstructured intervals. This Swedish training method offers a playful yet effective approach to building endurance.</p>
        <p>After a thorough 8-minute warm-up, alternate between 1-minute hard efforts and 2-minute easy jogs. Feel free to adjust the intensity based on how you feel and the terrain - that's the beauty of Fartlek training. Include some uphill sections for added challenge, and finish with a 5-minute cool-down jog.</p>
      </>,
      "tags": ["cardio"],
      "length_in_min": 35,
      "difficulty": "medium"
    }
  ],
  'federico_traeger': [
    {
      "id": "federico_traeger_2",
      "title": "Explore Primrose Hill with stability drills",
      "short_description": "Include single-leg drills to improve balance and core strength. Few stability-focused sessions observed in past runs.",
      "full_description": <>
        <p>Enhance your Primrose Hill session with targeted stability work. This workout incorporates specific drills to improve balance, core strength, and overall movement quality - areas that could benefit from additional focus based on your activity history.</p>
        <p>Start with a 10-minute easy run, then find a flat area for drills. Perform 3 sets of: 30-second single-leg stands (each leg), walking lunges with rotation (20 steps), and single-leg hops (10 each side). Between sets, jog easily for 3 minutes. Finish with some light running to cool down.</p>
      </>,
      "tags": ["stability", "strength"],
      "length_in_min": 25,
      "difficulty": "medium"
    }
  ],
  '9411535': [
    {
      "id": "9411535_2",
      "title": "Turn your Belsize Park run into hill drills",
      "short_description": "Add uphill efforts to strengthen legs and improve balance. Few hill-focused sessions seen, great for strength and stability.",
      "full_description": <>
        <p>Maximize your Belsize Park route with purposeful hill training. This session focuses on building leg strength and improving stability through controlled uphill efforts - addressing areas that could use additional attention in your training.</p>
        <p>Begin with a 7-minute warm-up on flat ground. Find a moderate hill and perform 6-8 uphill efforts of 30 seconds each, focusing on powerful push-offs and maintaining an upright posture. Walk or jog down for recovery. Between sets, perform 10 walking lunges to maintain muscle engagement. End with an easy 5-minute cool-down.</p>
      </>,
      "tags": ["strength", "stability"],
      "length_in_min": 25,
      "difficulty": "medium"
    }
  ],
  'fred_speirs': [
    {
      "id": "fred_speirs_2",
      "title": "Add hills to your Marylebone lunch run 🏃‍♂️",
      "short_description": "Incorporate short hill sprints to boost leg strength and improve balance. Great for longevity and joint health.",
      "full_description": <>
        <p>Enhance your lunch run with targeted hill sprints around Marylebone. This workout is designed to boost your leg strength and improve balance while maintaining a smooth pace.</p>
        <p>Find 4-5 moderate hills along your route. On each climb, maintain a steady seated position for the first half, then transition to a standing climb for the second half. Keep your cadence between 60-70 RPM during climbs. Between hills, recover with 3-5 minutes of easy spinning to prepare for the next ascent.</p>
      </>,
      "tags": ["strength", "stability"],
      "length_in_min": 25,
      "difficulty": "medium"
    }
  ],
  'timothe_jauffret': [
    {
      "id": "timothe_jauffret_2",
      "title": "Add hill repeats to your Gràcia bike ride ⛰️",
      "short_description": "Strengthen legs and improve cardio with hill drills. Few hill-focused rides seen, great for muscle and endurance gains.",
      "full_description": <>
        <p>Maximize your Gràcia bike ride with purposeful hill training. This session focuses on building leg strength and improving stability through controlled uphill efforts - addressing areas that could use additional attention in your training.</p>
        <p>Begin with a 7-minute warm-up on flat ground. Find a moderate hill and perform 6-8 uphill efforts of 30 seconds each, focusing on powerful push-offs and maintaining an upright posture. Walk or jog down for recovery. Between sets, perform 10 walking lunges to maintain muscle engagement. End with an easy 5-minute cool-down.</p>
      </>,
      "tags": ["strength", "cardio"],
      "length_in_min": 25,
      "difficulty": "medium"
    }
  ]
  // Add more user-specific workout arrays as needed
};

export const getWorkoutById = (workoutId) => {
  // Search through all workout arrays
  for (const userWorkouts of Object.values(workout_data)) {
    const workout = userWorkouts.find(w => w.id === workoutId);
    if (workout) return workout;
  }
  return null;
};

export const getWorkoutsForUser = (userData) => {
  if (!userData?.auth?.strava_username) {
    return workout_data.default;
  }
  
  const username = userData.auth.strava_username;
  const userSpecificWorkouts = workout_data[username];
  
  // If user has specific workouts, return those
  if (userSpecificWorkouts) {
    // Get the user's default workouts (ones that start with their username)
    const userDefaultWorkouts = workout_data.default.filter(w => 
      w.id.startsWith(username)
    );
    // Combine user's default workouts with their specific workouts
    return [...userDefaultWorkouts, ...userSpecificWorkouts];
  }
  
  // If no user-specific workouts, return all default workouts
  return workout_data.default;
};