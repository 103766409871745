import { getWorkoutsForUser } from '../calc/hc_workout_data';
import { BASE_URL } from '../../../config';

export const getWorkoutSuggestions = async (userData) => {
  if (!userData?.auth?.strava_username) {
    return [];
  }

  const maxRetries = 15; // 6 seconds with 500ms intervals
  let retryCount = 0;

  const fetchWithRetry = async () => {
    try {
      const response = await fetch(`${BASE_URL}/workouts/${userData.auth.strava_username}`);
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      
      if (!data || !data.workouts || data.workouts.length === 0) {
        if (retryCount < maxRetries) {
          retryCount++;
          await new Promise(resolve => setTimeout(resolve, 500));
          return fetchWithRetry();
        }
        return [];
      }
      
      return data.workouts;
    } catch (error) {
      console.error("Error fetching workout suggestions:", error);
      if (retryCount < maxRetries) {
        retryCount++;
        await new Promise(resolve => setTimeout(resolve, 500));
        return fetchWithRetry();
      }
      return [];
    }
  };

  return fetchWithRetry();
};
