export const profileMetrics = [
  // {
  //   metric_code: "healthy_until",
  //   name: "Healthspan",
  //   description: "Projected years with good health",
  //   unit: "healthy years",
  //   show_unit: true,
  //   emoji: "👨‍🦳",
  //   isMainWidget: true,
  //   decimalPlaces: 0,
  // },

  {
    metric_code: "life_score",
    simple_name: "Life Score",
    real_name: "Life Score",

    unit: "%",
    show_unit: true,
    emoji: "👨‍🦳",
    isMainWidget: true,
    decimalPlaces: 0,
  },

  {
    metric_code: "v02_max",
    simple_name: "Endurance",
    real_name: "VO2 max",
    unit: "ml/kg/min",
    show_unit: false,
    emoji: "🫁",
    isMainWidget: false,
    decimalPlaces: 0,
    minimalRequirement: "12 mins intense run required",
  },

  {
    metric_code: "ftp",
    simple_name: "Leg Power",
    real_name: "Cycling FTP",
    unit: "watts",
    show_unit: false,
    emoji: "🚴",
    isMainWidget: false,
    decimalPlaces: 0,
    minimalRequirement: "20 mins cycling test required",
  },

  {
    metric_code: "stability_score",
    simple_name: "Stability",
    real_name: "FMS Score",
    unit: " /3",
    show_unit: true,
    emoji: "🧘",
    isMainWidget: false,
    decimalPlaces: 1,
    minimalRequirement: "Simple movement assessment needed",
  },


  {
    metric_code: "output_consistency",
    simple_name: "Output Consistency",
    real_name: "Consistency",
    unit: " /3",
    show_unit: true,
    emoji: "🧘",
    isMainWidget: false,
    decimalPlaces: 1,
    minimalRequirement: "Simple movement assessment needed",
  },
  // {
  //   metric_code: "wilks_score",
  //   name: "Strength",
  //   description: "Wilks score",
  //   unit: "",
  //   show_unit: false,
  //   emoji: "🏋️‍♂️",
  //   isMainWidget: false,
  //   minimalRequirement: "Weightlifting session required",
  // },
];

export const distributionTables = {
  healthy_until: {
    M: {
      20: {
        0.05: 63,
        0.1: 65,
        0.15: 67,
        0.2: 68,
        0.25: 69,
        0.3: 70,
        0.35: 71,
        0.4: 72,
        0.45: 73,
        0.5: 75,
        0.55: 76,
        0.6: 77,
        0.65: 78,
        0.7: 80,
        0.75: 81,
        0.8: 82,
        0.85: 83,
        0.9: 85,
        0.91: 86,
        0.92: 87,
        0.93: 88,
        0.94: 89,
        0.95: 90,
        0.96: 91,
        0.97: 92,
        0.98: 93,
        0.99: 94,
      },
      30: {
        0.05: 61,
        0.1: 63,
        0.15: 65,
        0.2: 66,
        0.25: 67,
        0.3: 68,
        0.35: 69,
        0.4: 70,
        0.45: 71,
        0.5: 73,
        0.55: 74,
        0.6: 75,
        0.65: 76,
        0.7: 78,
        0.75: 79,
        0.8: 80,
        0.85: 81,
        0.9: 83,
        0.91: 84,
        0.92: 85,
        0.93: 86,
        0.94: 87,
        0.95: 88,
        0.96: 89,
        0.97: 90,
        0.98: 91,
        0.99: 92,
      },
      40: {
        0.05: 59,
        0.1: 61,
        0.15: 63,
        0.2: 64,
        0.25: 65,
        0.3: 66,
        0.35: 67,
        0.4: 68,
        0.45: 69,
        0.5: 71,
        0.55: 72,
        0.6: 73,
        0.65: 74,
        0.7: 76,
        0.75: 77,
        0.8: 78,
        0.85: 79,
        0.9: 81,
        0.91: 82,
        0.92: 83,
        0.93: 84,
        0.94: 85,
        0.95: 86,
        0.96: 87,
        0.97: 88,
        0.98: 89,
        0.99: 90,
      },
      50: {
        0.05: 57,
        0.1: 59,
        0.15: 61,
        0.2: 62,
        0.25: 63,
        0.3: 64,
        0.35: 65,
        0.4: 66,
        0.45: 67,
        0.5: 69,
        0.55: 70,
        0.6: 71,
        0.65: 72,
        0.7: 74,
        0.75: 75,
        0.8: 76,
        0.85: 77,
        0.9: 79,
        0.91: 80,
        0.92: 81,
        0.93: 82,
        0.94: 83,
        0.95: 84,
        0.96: 85,
        0.97: 86,
        0.98: 87,
        0.99: 88,
      },
      60: {
        0.05: 55,
        0.1: 57,
        0.15: 59,
        0.2: 60,
        0.25: 61,
        0.3: 62,
        0.35: 63,
        0.4: 64,
        0.45: 65,
        0.5: 67,
        0.55: 68,
        0.6: 69,
        0.65: 70,
        0.7: 72,
        0.75: 73,
        0.8: 74,
        0.85: 75,
        0.9: 77,
        0.91: 78,
        0.92: 79,
        0.93: 80,
        0.94: 81,
        0.95: 82,
        0.96: 83,
        0.97: 84,
        0.98: 85,
        0.99: 86,
      },
      70: {
        0.05: 53,
        0.1: 55,
        0.15: 57,
        0.2: 58,
        0.25: 59,
        0.3: 60,
        0.35: 61,
        0.4: 62,
        0.45: 63,
        0.5: 65,
        0.55: 66,
        0.6: 67,
        0.65: 68,
        0.7: 70,
        0.75: 71,
        0.8: 72,
        0.85: 73,
        0.9: 75,
        0.91: 76,
        0.92: 77,
        0.93: 78,
        0.94: 79,
        0.95: 80,
        0.96: 81,
        0.97: 82,
        0.98: 83,
        0.99: 84,
      },
    },
    F: {
      20: {
        0.05: 65,
        0.1: 67,
        0.15: 69,
        0.2: 70,
        0.25: 71,
        0.3: 72,
        0.35: 73,
        0.4: 74,
        0.45: 75,
        0.5: 77,
        0.55: 78,
        0.6: 79,
        0.65: 80,
        0.7: 82,
        0.75: 83,
        0.8: 84,
        0.85: 85,
        0.9: 87,
        0.91: 88,
        0.92: 89,
        0.93: 90,
        0.94: 91,
        0.95: 92,
        0.96: 93,
        0.97: 94,
        0.98: 95,
        0.99: 96,
      },
      30: {
        0.05: 63,
        0.1: 65,
        0.15: 67,
        0.2: 68,
        0.25: 69,
        0.3: 70,
        0.35: 71,
        0.4: 72,
        0.45: 73,
        0.5: 75,
        0.55: 76,
        0.6: 77,
        0.65: 78,
        0.7: 80,
        0.75: 81,
        0.8: 82,
        0.85: 83,
        0.9: 85,
        0.91: 86,
        0.92: 87,
        0.93: 88,
        0.94: 89,
        0.95: 90,
        0.96: 91,
        0.97: 92,
        0.98: 93,
        0.99: 94,
      },
      40: {
        0.05: 61,
        0.1: 63,
        0.15: 65,
        0.2: 66,
        0.25: 67,
        0.3: 68,
        0.35: 69,
        0.4: 70,
        0.45: 71,
        0.5: 73,
        0.55: 74,
        0.6: 75,
        0.65: 76,
        0.7: 78,
        0.75: 79,
        0.8: 80,
        0.85: 81,
        0.9: 83,
        0.91: 84,
        0.92: 85,
        0.93: 86,
        0.94: 87,
        0.95: 88,
        0.96: 89,
        0.97: 90,
        0.98: 91,
        0.99: 92,
      },
      50: {
        0.05: 59,
        0.1: 61,
        0.15: 63,
        0.2: 64,
        0.25: 65,
        0.3: 66,
        0.35: 67,
        0.4: 68,
        0.45: 69,
        0.5: 71,
        0.55: 72,
        0.6: 73,
        0.65: 74,
        0.7: 76,
        0.75: 77,
        0.8: 78,
        0.85: 79,
        0.9: 81,
        0.91: 82,
        0.92: 83,
        0.93: 84,
        0.94: 85,
        0.95: 86,
        0.96: 87,
        0.97: 88,
        0.98: 89,
        0.99: 90,
      },
      60: {
        0.05: 57,
        0.1: 59,
        0.15: 61,
        0.2: 62,
        0.25: 63,
        0.3: 64,
        0.35: 65,
        0.4: 66,
        0.45: 67,
        0.5: 69,
        0.55: 70,
        0.6: 71,
        0.65: 72,
        0.7: 74,
        0.75: 75,
        0.8: 76,
        0.85: 77,
        0.9: 79,
        0.91: 80,
        0.92: 81,
        0.93: 82,
        0.94: 83,
        0.95: 84,
        0.96: 85,
        0.97: 86,
        0.98: 87,
        0.99: 88,
      },
      70: {
        0.05: 55,
        0.1: 57,
        0.15: 59,
        0.2: 60,
        0.25: 61,
        0.3: 62,
        0.35: 63,
        0.4: 64,
        0.45: 65,
        0.5: 67,
        0.55: 68,
        0.6: 69,
        0.65: 70,
        0.7: 72,
        0.75: 73,
        0.8: 74,
        0.85: 75,
        0.9: 77,
        0.91: 78,
        0.92: 79,
        0.93: 80,
        0.94: 81,
        0.95: 82,
        0.96: 83,
        0.97: 84,
        0.98: 85,
        0.99: 86,
      },
    },
  },
  wilks_score: {
    M: {
      20: { 0.1: 200, 0.3: 250, 0.5: 300, 0.7: 350, 0.9: 400 },
      30: { 0.1: 220, 0.3: 270, 0.5: 320, 0.7: 370, 0.9: 420 },
      40: { 0.1: 210, 0.3: 260, 0.5: 310, 0.7: 360, 0.9: 410 },
      50: { 0.1: 200, 0.3: 250, 0.5: 300, 0.7: 350, 0.9: 400 },
      60: { 0.1: 190, 0.3: 240, 0.5: 290, 0.7: 340, 0.9: 390 },
      70: { 0.1: 180, 0.3: 230, 0.5: 280, 0.7: 330, 0.9: 380 },
    },
    F: {
      20: { 0.1: 150, 0.3: 200, 0.5: 250, 0.7: 300, 0.9: 350 },
      30: { 0.1: 170, 0.3: 220, 0.5: 270, 0.7: 320, 0.9: 370 },
      40: { 0.1: 160, 0.3: 210, 0.5: 260, 0.7: 310, 0.9: 360 },
      50: { 0.1: 150, 0.3: 200, 0.5: 250, 0.7: 300, 0.9: 350 },
      60: { 0.1: 140, 0.3: 190, 0.5: 240, 0.7: 290, 0.9: 340 },
      70: { 0.1: 130, 0.3: 180, 0.5: 230, 0.7: 280, 0.9: 330 },
    },
  },
  ftp: {
    M: {
      20: {
        0.1: 200,
        0.3: 250,
        0.5: 300,
        0.7: 350,
        0.9: 400,
        0.91: 405,
        0.92: 410,
        0.93: 415,
        0.94: 420,
        0.95: 425,
        0.96: 430,
        0.97: 435,
        0.98: 440,
        0.99: 445,
      },
      30: {
        0.1: 190,
        0.3: 240,
        0.5: 290,
        0.7: 340,
        0.9: 390,
        0.91: 395,
        0.92: 400,
        0.93: 405,
        0.94: 410,
        0.95: 415,
        0.96: 420,
        0.97: 425,
        0.98: 430,
        0.99: 435,
      },
      40: {
        0.1: 180,
        0.3: 230,
        0.5: 280,
        0.7: 330,
        0.9: 380,
        0.91: 385,
        0.92: 390,
        0.93: 395,
        0.94: 400,
        0.95: 405,
        0.96: 410,
        0.97: 415,
        0.98: 420,
        0.99: 425,
      },
      50: {
        0.1: 170,
        0.3: 220,
        0.5: 270,
        0.7: 320,
        0.9: 370,
        0.91: 375,
        0.92: 380,
        0.93: 385,
        0.94: 390,
        0.95: 395,
        0.96: 400,
        0.97: 405,
        0.98: 410,
        0.99: 415,
      },
      60: {
        0.1: 160,
        0.3: 210,
        0.5: 260,
        0.7: 310,
        0.9: 360,
        0.91: 365,
        0.92: 370,
        0.93: 375,
        0.94: 380,
        0.95: 385,
        0.96: 390,
        0.97: 395,
        0.98: 400,
        0.99: 405,
      },
      70: {
        0.1: 150,
        0.3: 200,
        0.5: 250,
        0.7: 300,
        0.9: 350,
        0.91: 355,
        0.92: 360,
        0.93: 365,
        0.94: 370,
        0.95: 375,
        0.96: 380,
        0.97: 385,
        0.98: 390,
        0.99: 395,
      },
    },
    F: {
      20: {
        0.1: 180,
        0.3: 230,
        0.5: 280,
        0.7: 330,
        0.9: 380,
        0.91: 385,
        0.92: 390,
        0.93: 395,
        0.94: 400,
        0.95: 405,
        0.96: 410,
        0.97: 415,
        0.98: 420,
        0.99: 425,
      },
      30: {
        0.1: 170,
        0.3: 220,
        0.5: 270,
        0.7: 320,
        0.9: 370,
        0.91: 375,
        0.92: 380,
        0.93: 385,
        0.94: 390,
        0.95: 395,
        0.96: 400,
        0.97: 405,
        0.98: 410,
        0.99: 415,
      },
      40: {
        0.1: 160,
        0.3: 210,
        0.5: 260,
        0.7: 310,
        0.9: 360,
        0.91: 365,
        0.92: 370,
        0.93: 375,
        0.94: 380,
        0.95: 385,
        0.96: 390,
        0.97: 395,
        0.98: 400,
        0.99: 405,
      },
      50: {
        0.1: 150,
        0.3: 200,
        0.5: 250,
        0.7: 300,
        0.9: 350,
        0.91: 355,
        0.92: 360,
        0.93: 365,
        0.94: 370,
        0.95: 375,
        0.96: 380,
        0.97: 385,
        0.98: 390,
        0.99: 395,
      },
      60: {
        0.1: 140,
        0.3: 190,
        0.5: 240,
        0.7: 290,
        0.9: 340,
        0.91: 345,
        0.92: 350,
        0.93: 355,
        0.94: 360,
        0.95: 365,
        0.96: 370,
        0.97: 375,
        0.98: 380,
        0.99: 385,
      },
      70: {
        0.1: 130,
        0.3: 180,
        0.5: 230,
        0.7: 280,
        0.9: 330,
        0.91: 335,
        0.92: 340,
        0.93: 345,
        0.94: 350,
        0.95: 355,
        0.96: 360,
        0.97: 365,
        0.98: 370,
        0.99: 375,
      },
    },
  },
  stability_score: {
    M: {
      20: {
        0.1: 1,
        0.3: 1.5,
        0.5: 2,
        0.7: 2.5,
        0.9: 3,
        0.91: 3.02,
        0.92: 3.04,
        0.93: 3.06,
        0.94: 3.08,
        0.95: 3.1,
        0.96: 3.12,
        0.97: 3.14,
        0.98: 3.16,
        0.99: 3.18,
      },
      30: {
        0.1: 1,
        0.3: 1.5,
        0.5: 2,
        0.7: 2.5,
        0.9: 3,
        0.91: 3.02,
        0.92: 3.04,
        0.93: 3.06,
        0.94: 3.08,
        0.95: 3.1,
        0.96: 3.12,
        0.97: 3.14,
        0.98: 3.16,
        0.99: 3.18,
      },
      40: {
        0.1: 1,
        0.3: 1.5,
        0.5: 2,
        0.7: 2.5,
        0.9: 3,
        0.91: 3.02,
        0.92: 3.04,
        0.93: 3.06,
        0.94: 3.08,
        0.95: 3.1,
        0.96: 3.12,
        0.97: 3.14,
        0.98: 3.16,
        0.99: 3.18,
      },
      50: {
        0.1: 0.5,
        0.3: 1,
        0.5: 1.5,
        0.7: 2,
        0.9: 2.5,
        0.91: 2.52,
        0.92: 2.54,
        0.93: 2.56,
        0.94: 2.58,
        0.95: 2.6,
        0.96: 2.62,
        0.97: 2.64,
        0.98: 2.66,
        0.99: 2.68,
      },
      60: {
        0.1: 0.5,
        0.3: 1,
        0.5: 1.5,
        0.7: 2,
        0.9: 2.5,
        0.91: 2.52,
        0.92: 2.54,
        0.93: 2.56,
        0.94: 2.58,
        0.95: 2.6,
        0.96: 2.62,
        0.97: 2.64,
        0.98: 2.66,
        0.99: 2.68,
      },
      70: {
        0.1: 0.5,
        0.3: 1,
        0.5: 1.5,
        0.7: 2,
        0.9: 2.5,
        0.91: 2.52,
        0.92: 2.54,
        0.93: 2.56,
        0.94: 2.58,
        0.95: 2.6,
        0.96: 2.62,
        0.97: 2.64,
        0.98: 2.66,
        0.99: 2.68,
      },
    },
    F: {
      20: {
        0.1: 1,
        0.3: 1.5,
        0.5: 2,
        0.7: 2.5,
        0.9: 3,
        0.91: 3.02,
        0.92: 3.04,
        0.93: 3.06,
        0.94: 3.08,
        0.95: 3.1,
        0.96: 3.12,
        0.97: 3.14,
        0.98: 3.16,
        0.99: 3.18,
      },
      30: {
        0.1: 1,
        0.3: 1.5,
        0.5: 2,
        0.7: 2.5,
        0.9: 3,
        0.91: 3.02,
        0.92: 3.04,
        0.93: 3.06,
        0.94: 3.08,
        0.95: 3.1,
        0.96: 3.12,
        0.97: 3.14,
        0.98: 3.16,
        0.99: 3.18,
      },
      40: {
        0.1: 1,
        0.3: 1.5,
        0.5: 2,
        0.7: 2.5,
        0.9: 3,
        0.91: 3.02,
        0.92: 3.04,
        0.93: 3.06,
        0.94: 3.08,
        0.95: 3.1,
        0.96: 3.12,
        0.97: 3.14,
        0.98: 3.16,
        0.99: 3.18,
      },
      50: {
        0.1: 0.5,
        0.3: 1,
        0.5: 1.5,
        0.7: 2,
        0.9: 2.5,
        0.91: 2.52,
        0.92: 2.54,
        0.93: 2.56,
        0.94: 2.58,
        0.95: 2.6,
        0.96: 2.62,
        0.97: 2.64,
        0.98: 2.66,
        0.99: 2.68,
      },
      60: {
        0.1: 0.5,
        0.3: 1,
        0.5: 1.5,
        0.7: 2,
        0.9: 2.5,
        0.91: 2.52,
        0.92: 2.54,
        0.93: 2.56,
        0.94: 2.58,
        0.95: 2.6,
        0.96: 2.62,
        0.97: 2.64,
        0.98: 2.66,
        0.99: 2.68,
      },
      70: {
        0.1: 0.5,
        0.3: 1,
        0.5: 1.5,
        0.7: 2,
        0.9: 2.5,
        0.91: 2.52,
        0.92: 2.54,
        0.93: 2.56,
        0.94: 2.58,
        0.95: 2.6,
        0.96: 2.62,
        0.97: 2.64,
        0.98: 2.66,
        0.99: 2.68,
      },
    },
  },
  v02_max: {
    M: {
      20: {
        0.1: 35,
        0.3: 40,
        0.5: 45,
        0.7: 50,
        0.9: 55,
        0.91: 55.5,
        0.92: 56,
        0.93: 56.5,
        0.94: 57,
        0.95: 57.5,
        0.96: 58,
        0.97: 58.5,
        0.98: 59,
        0.99: 59.5,
      },
      30: {
        0.1: 33,
        0.3: 38,
        0.5: 43,
        0.7: 48,
        0.9: 53,
        0.91: 53.5,
        0.92: 54,
        0.93: 54.5,
        0.94: 55,
        0.95: 55.5,
        0.96: 56,
        0.97: 56.5,
        0.98: 57,
        0.99: 57.5,
      },
      40: {
        0.1: 31,
        0.3: 36,
        0.5: 41,
        0.7: 46,
        0.9: 51,
        0.91: 51.5,
        0.92: 52,
        0.93: 52.5,
        0.94: 53,
        0.95: 53.5,
        0.96: 54,
        0.97: 54.5,
        0.98: 55,
        0.99: 55.5,
      },
      50: {
        0.1: 29,
        0.3: 34,
        0.5: 39,
        0.7: 44,
        0.9: 49,
        0.91: 49.5,
        0.92: 50,
        0.93: 50.5,
        0.94: 51,
        0.95: 51.5,
        0.96: 52,
        0.97: 52.5,
        0.98: 53,
        0.99: 53.5,
      },
      60: {
        0.1: 27,
        0.3: 32,
        0.5: 37,
        0.7: 42,
        0.9: 47,
        0.91: 47.5,
        0.92: 48,
        0.93: 48.5,
        0.94: 49,
        0.95: 49.5,
        0.96: 50,
        0.97: 50.5,
        0.98: 51,
        0.99: 51.5,
      },
      70: {
        0.1: 25,
        0.3: 30,
        0.5: 35,
        0.7: 40,
        0.9: 45,
        0.91: 45.5,
        0.92: 46,
        0.93: 46.5,
        0.94: 47,
        0.95: 47.5,
        0.96: 48,
        0.97: 48.5,
        0.98: 49,
        0.99: 49.5,
      },
    },
    F: {
      20: {
        0.1: 30,
        0.3: 35,
        0.5: 40,
        0.7: 45,
        0.9: 50,
        0.91: 50.5,
        0.92: 51,
        0.93: 51.5,
        0.94: 52,
        0.95: 52.5,
        0.96: 53,
        0.97: 53.5,
        0.98: 54,
        0.99: 54.5,
      },
      30: {
        0.1: 28,
        0.3: 33,
        0.5: 38,
        0.7: 43,
        0.9: 48,
        0.91: 48.5,
        0.92: 49,
        0.93: 49.5,
        0.94: 50,
        0.95: 50.5,
        0.96: 51,
        0.97: 51.5,
        0.98: 52,
        0.99: 52.5,
      },
      40: {
        0.1: 26,
        0.3: 31,
        0.5: 36,
        0.7: 41,
        0.9: 46,
        0.91: 46.5,
        0.92: 47,
        0.93: 47.5,
        0.94: 48,
        0.95: 48.5,
        0.96: 49,
        0.97: 49.5,
        0.98: 50,
        0.99: 50.5,
      },
      50: {
        0.1: 24,
        0.3: 29,
        0.5: 34,
        0.7: 39,
        0.9: 44,
        0.91: 44.5,
        0.92: 45,
        0.93: 45.5,
        0.94: 46,
        0.95: 46.5,
        0.96: 47,
        0.97: 47.5,
        0.98: 48,
        0.99: 48.5,
      },
      60: {
        0.1: 22,
        0.3: 27,
        0.5: 32,
        0.7: 37,
        0.9: 42,
        0.91: 42.5,
        0.92: 43,
        0.93: 43.5,
        0.94: 44,
        0.95: 44.5,
        0.96: 45,
        0.97: 45.5,
        0.98: 46,
        0.99: 46.5,
      },
      70: {
        0.1: 20,
        0.3: 25,
        0.5: 30,
        0.7: 35,
        0.9: 40,
        0.91: 40.5,
        0.92: 41,
        0.93: 41.5,
        0.94: 42,
        0.95: 42.5,
        0.96: 43,
        0.97: 43.5,
        0.98: 44,
        0.99: 44.5,
      },
    },
  },
};
