import React, { useState } from 'react';
import './FullProfile.scss';
import { WidgetFullProfile } from '../v3_components/Widget';
import { createDimDataForProfilev3 } from '../../utils/metrics_processing';
import Dimension from '../v3_components/Dimension';
import cardioIcon from '../../images/cardio_colour.svg';
import strengthIcon from '../../images/strength_colour.svg';
import stabilityIcon from '../../images/stability_colour.svg';
import backIcon from '../../images/back.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const FullProfile = ({ userData, onClose }) => {
  const [showDimension, setShowDimension] = useState(null);
  const dimData = createDimDataForProfilev3(userData);

  if (showDimension) {
    return (
      <Dimension 
        dimension={showDimension} 
        dimData={dimData}
        onClose={() => setShowDimension(null)} 
      />
    );
  }

  return (
    <div className="full-profile">
      <div className="container">
        {/* Header */}
        <div className="dimension-header">
          <div className="title-wrapper" onClick={onClose}>
            <div className="category-icon">
              <FontAwesomeIcon icon={faChevronLeft} className="back-chevron" />
            </div>
            <h1>Health Profile</h1>
          </div>
        </div>

        {/* Dimensions */}
        <div className="dimension-section">
          <div className="dimension-title">
            <img src={cardioIcon} alt="Cardio" />
            <span>Cardio</span>
          </div>
          <WidgetFullProfile
            title="Cardio"
            variant="cardio"
            icon={cardioIcon}
            mainMetric={{
              title: dimData?.cardio?.measure?.title,
              value: dimData?.cardio?.measure?.value,
              unit: dimData?.cardio?.measure?.unit
            }}
            percentile={dimData?.cardio?.measure?.percentile}
            precision={dimData?.cardio?.measure?.precision}
            onClick={() => setShowDimension('cardio')}
          />
        </div>

        <div className="dimension-section">
          <div className="dimension-title">
            <img src={strengthIcon} alt="Strength" />
            <span>Strength</span>
          </div>
          <WidgetFullProfile
            title="Strength"
            variant="strength"
            icon={strengthIcon}
            mainMetric={{
              title: dimData?.strength?.measure?.title || 'FTP',
              value: dimData?.strength?.measure?.value,
              unit: dimData?.strength?.measure?.unit
            }}
            percentile={dimData?.strength?.measure?.percentile}
            precision={dimData?.strength?.measure?.precision}
            onClick={() => setShowDimension('strength')}
          />
        </div>

        <div className="dimension-section">
          <div className="dimension-title">
            <img src={stabilityIcon} alt="Stability" />
            <span>Stability</span>
          </div>
          <WidgetFullProfile
            title="Stability"
            variant="stability"
            icon={stabilityIcon}
            mainMetric={{
              title: dimData?.stability?.measure?.title || 'Output Consistency',
              value: dimData?.stability?.measure?.value,
              unit: dimData?.stability?.measure?.unit
            }}
            percentile={dimData?.stability?.measure?.percentile}
            precision={dimData?.stability?.measure?.precision}
            onClick={() => setShowDimension('stability')}
          />
        </div>
      </div>
    </div>
  );
};

export default FullProfile;
