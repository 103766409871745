import { distributions, thresholds, declineRates, lifeExpectancy } from './supporting_data';

// Helper function to get percentile from distributions table
function getPercentile({ gender, age, metric_code, metric_value }) {
    // Return null if metric_value is null or undefined
    if (metric_value == null) {
        return null;
    }

    const table = distributions[metric_code][gender];
    if (!table) {
        console.warn(`No distribution table found for ${metric_code} and ${gender}`);
        return 0.5;
    }

    // Round age down to nearest 5
    const ageBracket = Math.floor(age / 5) * 5;
    const data = table[ageBracket];
    
    if (!data) {
        console.warn(`No age bracket ${ageBracket} found for ${metric_code} and ${gender}`);
        return 0.5;
    }

    // Find the appropriate percentile by checking thresholds
    const percentileKeys = [0.05, 0.25, 0.5, 0.75, 0.9, 0.95, 0.98, 0.99];
    let percentile = 0.05; // Start with lowest percentile

    for (let i = 0; i < percentileKeys.length; i++) {
        const key = percentileKeys[i];
        if (metric_value >= data[key]) {
            percentile = key;
        } else {
            break;
        }
    }

    return percentile;
}

// Add this function before getGoodYearsLeft
function getDeclineRate(metric, age) {
    const rates = declineRates[metric];
    if (!rates) {
        console.warn(`No decline rates found for metric: ${metric}`);
        return 0.01; // default 1% decline
    }

    // Find the appropriate rate based on age
    if (age >= rates.late.startAge) {
        return rates.late.rate;
    } else if (age >= rates.middle.startAge) {
        return rates.middle.rate;
    } else if (age >= rates.early.startAge) {
        return rates.early.rate;
    }
    
    return rates.early.rate; // Use early rate for ages before first threshold
}

// Helper function to calculate good years left
function getGoodYearsLeft({ age, gender, metrics }) {
    const showConsoleLogs = false;  // Toggle this to true/false to show/hide logs
    
    if (showConsoleLogs) console.log('\n=== Good Years Left Calculation ===');
    if (showConsoleLogs) console.log('Input:', { age, gender, metrics });
    if (showConsoleLogs) console.log('\nThresholds:', thresholds);

    let goodYearsLeft = 0;
    let longevityBonus = 0;

    // Calculate decline for each metric
    Object.entries(metrics).forEach(([metric, data]) => {
        const { value } = data;
        if (!value) {
            if (showConsoleLogs) console.log(`\n${metric}: No value provided, skipping`);
            return;
        }

        const threshold = thresholds[metric];
        const declineRate = getDeclineRate(metric, age);
        const totalYears = (value - threshold) / (value * declineRate);
        const yearsLeft = totalYears - age;  // Deduct current age to get years left

        if (showConsoleLogs) {
            console.log(`\n${metric} Analysis:`);
            console.log(`  Current Value: ${value}`);
            console.log(`  Threshold: ${threshold}`);
            console.log(`  Annual Decline Rate: ${(declineRate * 100).toFixed(2)}%`);
            console.log(`  Total Years: ${totalYears.toFixed(1)}`);
            console.log(`  Years Left: ${yearsLeft.toFixed(1)}`);
        }

        // Track if this becomes the limiting factor
        if (yearsLeft > 0 && (yearsLeft < goodYearsLeft || goodYearsLeft === 0)) {
            if (showConsoleLogs) console.log(`  >>> New limiting metric (previous: ${goodYearsLeft}, new: ${yearsLeft})`);
            goodYearsLeft = yearsLeft;
        }
    });

    // Enforce minimum 15 years
    goodYearsLeft = Math.max(15, goodYearsLeft);

    // Enforce reasonable maximum before life expectancy calculations
    goodYearsLeft = Math.min(goodYearsLeft, 100); // Cap at 100 years total

    if (showConsoleLogs) {
        console.log('\nFinal Calculations:');
        console.log(`  Good Years Left (raw): ${goodYearsLeft}`);
        console.log(`  Longevity Bonus: ${longevityBonus}`);
        console.log(`  Current Age: ${age}`);
    }

    // Get life expectancy for the age bracket
    const ageBracket = Math.floor(age / 5) * 5;
    const expectedLifespan = lifeExpectancy[gender][ageBracket] || 75;

    // Calculate years with penalty for beyond life expectancy
    const yearsToLifeExpectancy = expectedLifespan - age;
    const yearsBeyondExpectancy = Math.max(0, goodYearsLeft - yearsToLifeExpectancy);
    
    // Check for missing metrics
    const hasMissingMetrics = Object.values(metrics).some(data => !data.value);
    
    // Apply penalty factor to all years if metrics are missing
    const penaltyFactor = hasMissingMetrics ? 0.7 : 1; // Apply 20% penalty if metrics are missing

    // Calculate adjusted bonus years (years beyond life expectancy with additional penalty)
    const adjustedBonusYears = yearsBeyondExpectancy * penaltyFactor * 0.5; // 50% additional penalty for bonus years

    // Combine regular years and penalized bonus years
    const finalGoodYears = Math.round(
        Math.min(goodYearsLeft, yearsToLifeExpectancy) * penaltyFactor + adjustedBonusYears
    );

    const projectedAge = age + finalGoodYears;

    if (showConsoleLogs) {
        console.log('\nLife Expectancy Calculations:');
        console.log(`  Years to Life Expectancy: ${yearsToLifeExpectancy}`);
        console.log(`  Years Beyond Expectancy (raw): ${yearsBeyondExpectancy}`);
        console.log(`  Penalty Factor: ${penaltyFactor}x (increased due to missing metrics: ${hasMissingMetrics})`);
        console.log(`  Final Good Years: ${finalGoodYears}`);
        console.log(`  Projected Age: ${projectedAge}`);
    }

    return {
        goodYearsLeft: finalGoodYears,
        totalLifeExpectancy: expectedLifespan,
        projectedAge: projectedAge,
        years: {
            in_good_health: projectedAge,
            left: finalGoodYears,
            potential_gain: 5
        },
        limitingMetric: null,
        precision: Infinity
    };
}

export { getPercentile, getGoodYearsLeft };
  