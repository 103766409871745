export const distributions = {
  vo2_max: {
    male: {
      20: { 0.05: 25, 0.25: 35, 0.5: 45, 0.75: 52, 0.9: 60, 0.95: 65, 0.98: 70, 0.99: 75 },
      25: { 0.05: 24, 0.25: 34, 0.5: 44, 0.75: 51, 0.9: 59, 0.95: 64, 0.98: 69, 0.99: 74 },
      30: { 0.05: 23, 0.25: 33, 0.5: 43, 0.75: 50, 0.9: 58, 0.95: 63, 0.98: 68, 0.99: 73 },
      35: { 0.05: 22, 0.25: 32, 0.5: 42, 0.75: 49, 0.9: 57, 0.95: 62, 0.98: 67, 0.99: 72 },
      40: { 0.05: 21, 0.25: 31, 0.5: 41, 0.75: 48, 0.9: 56, 0.95: 61, 0.98: 66, 0.99: 71 },
      45: { 0.05: 20, 0.25: 30, 0.5: 40, 0.75: 47, 0.9: 55, 0.95: 60, 0.98: 65, 0.99: 70 },
      50: { 0.05: 19, 0.25: 29, 0.5: 39, 0.75: 46, 0.9: 54, 0.95: 59, 0.98: 64, 0.99: 69 },
      55: { 0.05: 18, 0.25: 28, 0.5: 38, 0.75: 45, 0.9: 53, 0.95: 58, 0.98: 63, 0.99: 68 },
      60: { 0.05: 17, 0.25: 27, 0.5: 37, 0.75: 44, 0.9: 52, 0.95: 57, 0.98: 62, 0.99: 67 }
    },
    female: {
      20: { 0.05: 95, 0.25: 125, 0.5: 160, 0.75: 190, 0.9: 220, 0.95: 235, 0.98: 250, 0.99: 265 },
      25: { 0.05: 93, 0.25: 123, 0.5: 158, 0.75: 188, 0.9: 218, 0.95: 233, 0.98: 248, 0.99: 263 },
      30: { 0.05: 91, 0.25: 121, 0.5: 156, 0.75: 186, 0.9: 216, 0.95: 231, 0.98: 246, 0.99: 261 },
      35: { 0.05: 89, 0.25: 119, 0.5: 154, 0.75: 184, 0.9: 214, 0.95: 229, 0.98: 244, 0.99: 259 },
      40: { 0.05: 87, 0.25: 117, 0.5: 152, 0.75: 182, 0.9: 212, 0.95: 227, 0.98: 242, 0.99: 257 },
      45: { 0.05: 85, 0.25: 115, 0.5: 150, 0.75: 180, 0.9: 210, 0.95: 225, 0.98: 240, 0.99: 255 },
      50: { 0.05: 83, 0.25: 113, 0.5: 148, 0.75: 178, 0.9: 208, 0.95: 223, 0.98: 238, 0.99: 253 },
      55: { 0.05: 81, 0.25: 111, 0.5: 146, 0.75: 176, 0.9: 206, 0.95: 221, 0.98: 236, 0.99: 251 },
      60: { 0.05: 79, 0.25: 109, 0.5: 144, 0.75: 174, 0.9: 204, 0.95: 219, 0.98: 234, 0.99: 249 }
    }
  },
  ftp_cycling: {
    male: {
      20: { 0.05: 130, 0.25: 170, 0.5: 210, 0.75: 250, 0.9: 280, 0.95: 300, 0.98: 320, 0.99: 340 },
      25: { 0.05: 128, 0.25: 168, 0.5: 208, 0.75: 248, 0.9: 278, 0.95: 298, 0.98: 318, 0.99: 338 },
      30: { 0.05: 125, 0.25: 165, 0.5: 205, 0.75: 245, 0.9: 275, 0.95: 295, 0.98: 315, 0.99: 335 },
      35: { 0.05: 122, 0.25: 162, 0.5: 202, 0.75: 242, 0.9: 272, 0.95: 292, 0.98: 312, 0.99: 332 },
      40: { 0.05: 120, 0.25: 160, 0.5: 200, 0.75: 240, 0.9: 270, 0.95: 290, 0.98: 310, 0.99: 330 },
      45: { 0.05: 117, 0.25: 157, 0.5: 197, 0.75: 237, 0.9: 267, 0.95: 287, 0.98: 307, 0.99: 327 },
      50: { 0.05: 115, 0.25: 155, 0.5: 195, 0.75: 235, 0.9: 265, 0.95: 285, 0.98: 305, 0.99: 325 },
      55: { 0.05: 112, 0.25: 152, 0.5: 192, 0.75: 232, 0.9: 262, 0.95: 282, 0.98: 302, 0.99: 322 },
      60: { 0.05: 110, 0.25: 150, 0.5: 190, 0.75: 230, 0.9: 260, 0.95: 280, 0.98: 300, 0.99: 320 }
    },
    female: {
        20: { 0.05: 95, 0.25: 125, 0.5: 160, 0.75: 190, 0.9: 220, 0.95: 235, 0.98: 250, 0.99: 265 },
        25: { 0.05: 93, 0.25: 123, 0.5: 158, 0.75: 188, 0.9: 218, 0.95: 233, 0.98: 248, 0.99: 263 },
        30: { 0.05: 91, 0.25: 121, 0.5: 156, 0.75: 186, 0.9: 216, 0.95: 231, 0.98: 246, 0.99: 261 },
        35: { 0.05: 89, 0.25: 119, 0.5: 154, 0.75: 184, 0.9: 214, 0.95: 229, 0.98: 244, 0.99: 259 },
        40: { 0.05: 87, 0.25: 117, 0.5: 152, 0.75: 182, 0.9: 212, 0.95: 227, 0.98: 242, 0.99: 257 },
        45: { 0.05: 85, 0.25: 115, 0.5: 150, 0.75: 180, 0.9: 210, 0.95: 225, 0.98: 240, 0.99: 255 },
        50: { 0.05: 83, 0.25: 113, 0.5: 148, 0.75: 178, 0.9: 208, 0.95: 223, 0.98: 238, 0.99: 253 },
        55: { 0.05: 81, 0.25: 111, 0.5: 146, 0.75: 176, 0.9: 206, 0.95: 221, 0.98: 236, 0.99: 251 },
        60: { 0.05: 79, 0.25: 109, 0.5: 144, 0.75: 174, 0.9: 204, 0.95: 219, 0.98: 234, 0.99: 249 }
      }
  },
  output_consistency: {
    male: {
      20: { 0.05: 0.8, 0.25: 1.2, 0.5: 1.6, 0.75: 2.0, 0.9: 2.3, 0.95: 2.5, 0.98: 2.7, 0.99: 2.9 },
      25: { 0.05: 0.8, 0.25: 1.2, 0.5: 1.6, 0.75: 2.0, 0.9: 2.3, 0.95: 2.5, 0.98: 2.7, 0.99: 2.9 },
      30: { 0.05: 0.8, 0.25: 1.2, 0.5: 1.6, 0.75: 2.0, 0.9: 2.3, 0.95: 2.5, 0.98: 2.7, 0.99: 2.9 },
      35: { 0.05: 0.7, 0.25: 1.1, 0.5: 1.5, 0.75: 1.9, 0.9: 2.2, 0.95: 2.4, 0.98: 2.6, 0.99: 2.8 },
      40: { 0.05: 0.7, 0.25: 1.1, 0.5: 1.5, 0.75: 1.9, 0.9: 2.2, 0.95: 2.4, 0.98: 2.6, 0.99: 2.8 },
      45: { 0.05: 0.7, 0.25: 1.1, 0.5: 1.5, 0.75: 1.9, 0.9: 2.2, 0.95: 2.4, 0.98: 2.6, 0.99: 2.8 },
      50: { 0.05: 0.6, 0.25: 1.0, 0.5: 1.4, 0.75: 1.8, 0.9: 2.1, 0.95: 2.3, 0.98: 2.5, 0.99: 2.7 },
      55: { 0.05: 0.6, 0.25: 1.0, 0.5: 1.4, 0.75: 1.8, 0.9: 2.1, 0.95: 2.3, 0.98: 2.5, 0.99: 2.7 },
      60: { 0.05: 0.6, 0.25: 1.0, 0.5: 1.4, 0.75: 1.8, 0.9: 2.1, 0.95: 2.3, 0.98: 2.5, 0.99: 2.7 }
    },
    female: {
      20: { 0.05: 0.8, 0.25: 1.2, 0.5: 1.6, 0.75: 2.0, 0.9: 2.3, 0.95: 2.5, 0.98: 2.7, 0.99: 2.9 },
      25: { 0.05: 0.8, 0.25: 1.2, 0.5: 1.6, 0.75: 2.0, 0.9: 2.3, 0.95: 2.5, 0.98: 2.7, 0.99: 2.9 },
      30: { 0.05: 0.8, 0.25: 1.2, 0.5: 1.6, 0.75: 2.0, 0.9: 2.3, 0.95: 2.5, 0.98: 2.7, 0.99: 2.9 },
      35: { 0.05: 0.7, 0.25: 1.1, 0.5: 1.5, 0.75: 1.9, 0.9: 2.2, 0.95: 2.4, 0.98: 2.6, 0.99: 2.8 },
      40: { 0.05: 0.7, 0.25: 1.1, 0.5: 1.5, 0.75: 1.9, 0.9: 2.2, 0.95: 2.4, 0.98: 2.6, 0.99: 2.8 },
      45: { 0.05: 0.7, 0.25: 1.1, 0.5: 1.5, 0.75: 1.9, 0.9: 2.2, 0.95: 2.4, 0.98: 2.6, 0.99: 2.8 },
      50: { 0.05: 0.6, 0.25: 1.0, 0.5: 1.4, 0.75: 1.8, 0.9: 2.1, 0.95: 2.3, 0.98: 2.5, 0.99: 2.7 },
      55: { 0.05: 0.6, 0.25: 1.0, 0.5: 1.4, 0.75: 1.8, 0.9: 2.1, 0.95: 2.3, 0.98: 2.5, 0.99: 2.7 },
      60: { 0.05: 0.6, 0.25: 1.0, 0.5: 1.4, 0.75: 1.8, 0.9: 2.1, 0.95: 2.3, 0.98: 2.5, 0.99: 2.7 }
    }
  }
};

export const thresholds = {
  vo2_max: 20, // ml/kg/min - minimum healthy value
  ftp_cycling: 100, // watts - minimum for meaningful analysis
  output_consistency: 0.7, // scale of 0-3 - minimum for reliable measurement
};

export const declineRates = {
  vo2_max: {
    early: { rate: 0.005, startAge: 25 },    // 0.5% per year from 25-45
    middle: { rate: 0.01, startAge: 45 },    // 1.0% per year from 45-65
    late: { rate: 0.015, startAge: 65 }      // 1.5% per year after 65
  },
  ftp_cycling: {
    early: { rate: 0.004, startAge: 30 },    // 0.4% per year from 30-50
    middle: { rate: 0.008, startAge: 50 },   // 0.8% per year from 50-70
    late: { rate: 0.012, startAge: 70 }      // 1.2% per year after 70
  },
  output_consistency: {
    early: { rate: 0.003, startAge: 35 },    // 0.3% per year from 35-55
    middle: { rate: 0.006, startAge: 55 },   // 0.6% per year from 55-75
    late: { rate: 0.009, startAge: 75 }      // 0.9% per year after 75
  }
};

export const lifeExpectancy = {
  male: {
    20: 77.8,
    25: 78.0,
    30: 78.2,
    35: 78.5,
    40: 78.8,
    45: 79.2,
    50: 79.7,
    55: 80.4,
    60: 81.3,
    65: 82.4,
    70: 83.8,
    75: 85.3,
    80: 87.0,
    85: 89.0,
    90: 91.0,
    95: 93.0,
    100: 95.0,
  },
  female: {
    20: 82.3,
    25: 82.4,
    30: 82.6,
    35: 82.8,
    40: 83.1,
    45: 83.5,
    50: 83.9,
    55: 84.5,
    60: 85.2,
    65: 86.1,
    70: 87.1,
    75: 88.5,
    80: 90.0,
    85: 92.0,
    90: 94.0,
    95: 96.0,
    100: 98.0,
  },
};
