import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import './WorkoutView.scss';

import cardioIcon from '../../images/cardio_icon.svg';
import strengthIcon from '../../images/strength_icon.svg';
import stabilityIcon from '../../images/stability_icon.svg';
import timerIcon from '../../images/timer.svg';

const WorkoutView = ({ workout, onClose }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getIconForTag = (tag) => {
    switch (tag.toLowerCase()) {
      case 'cardio': return cardioIcon;
      case 'strength': return strengthIcon;
      case 'stability': return stabilityIcon;
      default: return null;
    }
  };

  if (!workout) return null;

  return (
    <div className="workout-view-container">
      <div className="workout-view-header">
        <div className="header-content">
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="back-arrow"
            onClick={onClose}
          />
          <h1>Suggested Workout</h1>
        </div>
      </div>

      <div className="workout-view-content">
        <div className="dimension-tags">
          {workout.tags.map((tag, index) => (
            <span key={index} className={`workout-tag ${tag.toLowerCase()}`}>
              <img src={getIconForTag(tag)} alt="" className="tag-icon" />
              {tag.toUpperCase()}
            </span>
          ))}
        </div>

        <h2 className="workout-title">{workout.title}</h2>

        <div className="workout-meta">
          <span className="time-tag">
            <img src={timerIcon} alt="Duration" />
            {workout.time_in_mins} min
          </span>
          <span className="difficulty-tag">
            {workout.difficulty.toUpperCase()}
          </span>
        </div>

        <div className="workout-description">
          {workout.full_description}
        </div>

        {/* <div className="workout-actions">
          <button className="workout-button secondary">
            Mark as completed
          </button>
          <button className="workout-button primary">
           Build a training plan for real progress
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default WorkoutView;
