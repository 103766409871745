// src/components/reusable/TextInput.js
import React, { useState } from "react";
import "./TextInput.scss";

const TextInput = ({ placeholder, value, onChange, validation }) => {
  const handleChange = (e) => {
    const newValue = e.target.value;
    onChange(e);
  };

  return (
    <div className="textInput">
      <input
        type="email"
        autoComplete="email"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

export default TextInput;
