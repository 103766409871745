import React, { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import Button from "./reusable/Button";
import Dots from "./Dots";
import "./Onboarding.scss";
import stravaButton from "../images/btn_strava_connectwith_orange.svg";
import onboardingUnique from "../images/onboarding/onboarding_unique.png";
import attiaImage from "../images/onboarding/attia.png";
import info2Image from "../images/onboarding/info_2.png";
import bearded from "../images/onboarding/bearded_2.png";


import YearPicker from "./reusable/YearPicker";
import { BASE_URL } from "../config";
import TextInput from "./reusable/TextInput";

const Onboarding = () => {
  const [currentScreen, setCurrentScreen] = useState(0);
  const [selectedYear, setSelectedYear] = useState(1985);
  const [email, setEmail] = useState("");
  const [showError, setShowError] = useState(false);
  const [isTestMode, setIsTestMode] = useState(false);

  useEffect(() => {
    setIsTestMode(window.location.hostname.includes("localhost"));
  }, []);

  const screens = [
    {
      title: "Training for longevity is different",
      subtitle: (
        <>
          <p>
            Longevity training isn't about preparing for a race or building muscle—it's about staying active and capable for decades to come. The goal is to reach life milestones, like <i>being there for your grandchildren's weddings</i> or <i>hiking at 80</i>.
          </p>
          <p>
            Unlike other fitness plans, longevity training takes a generalist approach and introduces movements you might not have seen before.
          </p>

          {/* It balances strength, endurance, flexibility, and mobility,  */}
        </>
      ),
      image: bearded,
      buttonText: "Continue",
    },
    {
      title: "Focus on exercise for maximum impact",
      subtitle: (
        <>
          <p>
            
            Many factors influence longevity, including nutrition, sleep quality, and genetics. However, exercise stands out as <i>the most significant modifiable behavior</i>. As Peter Attia, a leading expert on longevity, puts it:
          </p>
          <p className="">
            <b>"More than any domain, exercise has the greatest power to determine how you will live out the rest of your life.</b> Anyone should prioritize exercise and achieve basic fitness standards before focusing on other health-related details like supplements or specific diet variations."
          </p>
        </>
      ),
      image: attiaImage,
      // attribution: "Peter Attia, MD, Author of Outlive",
      buttonText: "Continue",
    },
    {
      title: "How many good years do you have left?",
      subtitle: "More importantly, how good many years could you gain? Connect with Strava to get personalized insights on optimizing your training for a longer, healthier life.",
      image: onboardingUnique,
      buttonText: "Start",
    },
    {
      title: "One last step!",
      subtitle: "Enter your birth year, email and connect with Strava to unlock your journey to a longer, healthier life.",
      component: (
        <div className="input-container">
          <TextInput
            placeholder="Email (john@doe.com)"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setShowError(false);
            }}
          />
          <YearPicker
            onChange={(year) => setSelectedYear(year)}
            defaultYear={1985}
            startYear={1900}
            endYear={new Date().getFullYear() - 18}
          />
          {showError && (
            <div className="error-message">
              Please provide a valid email and birth year before connecting.
            </div>
          )}
        </div>
      ),
      buttons: (
        <div className="button-container strava-button">
          <img
            src={stravaButton}
            alt="Connect with Strava"
            height="48px"
            onClick={() => {
              if (selectedYear && validateEmail(email).validated) {
                const testModeParam = isTestMode ? "true" : "false";
                window.location.href = `${BASE_URL}/authorize?birth_year=${selectedYear}&email=${encodeURIComponent(email)}&testMode=${testModeParam}`;
              } else {
                setShowError(true);
              }
            }}
          />
        </div>
      ),
    },
  ];

  const { title, subtitle, image, buttons, buttonText, component, attribution } =
    screens[currentScreen];

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentScreen < screens.length - 1) {
        setCurrentScreen(currentScreen + 1);
      }
    },
    onSwipedRight: () => {
      if (currentScreen > 0) {
        setCurrentScreen(currentScreen - 1);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  console.log("attiaImage:", attiaImage);
  console.log("onboardingUnique:", onboardingUnique);

  return (
    <div className="onboarding" {...handlers}>
      <div className="header">
        <h1 className="onboarding-title">{title}</h1>
        {subtitle && <h2 className="onboarding-subtitle">{subtitle}</h2>}
        {attribution && <h3 className="onboarding-attribution">{attribution}</h3>}
      </div>
      <div className="content">
        {component || (
          <>
            {screens.map((screen, index) => (
              screen.image && (
                <img
                  key={index}
                  src={screen.image}
                  alt={screen.title}
                  className={`info-image ${currentScreen === index ? 'active' : ''}`}
                />
              )
            ))}
          </>
        )}
      </div>
      <div className="footer">
        <Dots total={screens.length} current={currentScreen} />
        {buttons
          ? buttons
          : buttonText && (
              <div className="button-container">
                <Button
                  color="black"
                  cta={buttonText}
                  onClick={() => setCurrentScreen(currentScreen + 1)}
                />
              </div>
            )}
      </div>
    </div>
  );
};

const validateEmail = (value) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(value)
    ? { validated: true, error_message: "" }
    : { validated: false, error_message: "Invalid email format" };
};

export default Onboarding;
