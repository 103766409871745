import React from 'react';
import './Dimension.scss';
import { content } from './content';
import { Widget, WidgetDimension } from './Widget';
import { Interpolated, Wiki } from './DimensionHelpers';
import { ReactComponent as CardioIcon } from '../../images/category_icons/cardio.svg';
import { ReactComponent as StabilityIcon } from '../../images/category_icons/stability.svg';
import { ReactComponent as StrengthIcon } from '../../images/category_icons/strength.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { getPercentileTextPrecise } from './PercentileTag';
import { MinPerDay, MeasuresLineGraph } from './Graph';

const getDimensionContent = (dimension, dimData) => {


    // console.log(dimData?.cardio?.effort?.past_week_data)


    switch (dimension) {
        case 'cardio':
            return (
                <>
                    <h2>Health overview</h2>

                    <WidgetDimension
                        title="Main Cardio Metrics"
                        variant="cardio"
                        percentile={dimData?.cardio?.measure?.percentile}
                        precision = {(dimData?.cardio?.measure?.precision * 100).toFixed(0)}
                        // precision={dimData?.cardio?.measure?.precision}
                        metrics={[
                            {
                                label: dimData?.cardio?.measure?.title || "VO2 max",
                                value: Math.round(dimData?.cardio?.measure?.value)?.toString() || "55",
                                unit: dimData?.cardio?.measure?.unit || "ml/kg/min"
                            },
                            {
                                label: "Healthy age",
                                value: dimData?.cardio?.measure?.decay_age?.toString() || "87",
                                unit: "year-old"
                            },
                            {
                                label: "Confidence",
                                value: dimData?.cardio?.measure?.precision
                                    ? `${(dimData?.cardio?.measure?.precision * 100).toFixed(0)}%`
                                    : "High ↑"
                            }
                        ]}
                    />

                    <div className="content-section">
                        <p>
                            Your <Wiki reference="VO2_max">{dimData?.cardio?.measure?.title || "VO2 max"}</Wiki> is{' '}
                            <Interpolated>{Math.round(dimData?.cardio?.measure?.value)?.toString() || "55"} {dimData?.cardio?.measure?.unit || "ml/kg/min"}</Interpolated>,
                            placing you in the <Interpolated>{getPercentileTextPrecise(dimData?.cardio?.measure?.percentile)}</Interpolated> of <Interpolated>{dimData?.details?.user_age_gender_group}</Interpolated>. 👏
                        </p>
                        <p>
                            Your VO2 max is <Wiki reference="cardio_decline">projected</Wiki> to reach minimal levels by age{' '}
                            <Interpolated>{dimData?.cardio?.measure?.decay_age || "72"}</Interpolated>
                            {(dimData?.cardio?.measure?.decay_age || 72) >= (dimData?.details?.life_expectancy || 84) ? (
                                <>. This isn't worrying, as that's above the life expectancy for your age and gender group (<Interpolated>{dimData?.details?.life_expectancy}</Interpolated> years old).</>
                            ) : (
                                <> - out of a total life expectancy of <Interpolated>{dimData?.details?.life_expectancy}</Interpolated></>
                            )}
                        </p>

                        <p>
                            {(dimData?.cardio?.measure?.precision || 0) >= 0.90 ? (
                                <>
                                    Your measure is quite precise, at{" "}
                                    <Interpolated>{(dimData?.cardio?.measure?.precision * 100).toFixed(0)}%</Interpolated>.
                                    It was calculated using a <Wiki reference="cooper_test">Cooper test</Wiki>.
                                </>
                            ) : (
                                <>
                                    Your measure is {(dimData?.cardio?.measure?.precision || 0) >= 0.80 ? 'fairly' : ''} <Interpolated>{(dimData?.cardio?.measure?.precision * 100 || 85).toFixed(0)}%</Interpolated> precise.
                                    You can improve it by running a <Wiki reference="cooper_test">Cooper test</Wiki>.
                                </>
                            )}

                            {dimData?.cardio?.measure?.activity_id && (
                                <>
                                    {" "}It used the training session{' '}
                                    <Interpolated>{dimData?.cardio?.measure?.activity_name}</Interpolated> on{' '}
                                    <Interpolated>
                                        {new Date(dimData?.cardio?.measure?.activity_date).toLocaleDateString()}
                                    </Interpolated>:{" "}
                                    <a
                                        href={`https://www.strava.com/activities/${dimData?.cardio?.measure?.activity_id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="strava-link"
                                    >
                                        View on Strava
                                    </a>.

                                </>
                            )}
                        </p>

                    </div>

                    <MeasuresLineGraph 
                        data={dimData?.cardio?.measure?.past_measures}
                        color="#ff1be1"
                        graphTitle="VO2 Max Measures"
                        unit="ml/kg/min"
                    />

                    <h2>Effort</h2>
                    {dimData?.cardio?.effort?.recommended?.nb_bars === 0 ? (
                        <span className="no-data-message">
                            No Zone 2 nor intense workout captured this past week.
                        </span>
                    ) : (
                        <>
                            <WidgetDimension
                                title="Past Week Activity"
                                variant="cardio"
                                effortBars={dimData?.cardio?.effort?.recommended?.nb_bars || 0}
                                effortTitle="Weekly Training"
                                metrics={[
                                    {
                                        label: "Zone 2 time",
                                        value: dimData?.cardio?.effort?.zone_2?.mins?.toString() || "0",
                                        unit: "min"
                                    },
                                    {
                                        label: "High intensity",
                                        value: dimData?.cardio?.effort?.high_intensity?.nb_activities?.toString() || "0",
                                        unit: "workouts"
                                    }
                                ]}
                            />

                            <MinPerDay 
                                data={dimData?.cardio?.effort?.past_week_data} 
                                color="#ff1be1" 
                                graphTitle="Zone 2 Training, Past Week"
                            />

                            <div className="content-section">
                                <p>In the past 7 days, you've completed:</p>
                                <ul>
                                    <li>
                                        <Interpolated>{dimData?.cardio?.effort?.zone_2?.mins || 0}</Interpolated> out of the recommended 180 minutes (
                                        <Interpolated>
                                            {Math.round((dimData?.cardio?.effort?.recommended?.zone_2_percentage_completed || 0) * 100)}%
                                        </Interpolated>) of{' '}<Wiki reference="zone_2">Zone 2</Wiki> exercise
                                    </li>
                                    <li>
                                        <Interpolated>{dimData?.cardio?.effort?.high_intensity?.nb_activities || 0}</Interpolated> out of the recommended 2 sessions 
                                        <Interpolated>
                                            {(dimData?.cardio?.effort?.high_intensity?.nb_activities || 0) > 2 
                                                ? "(over 100%)" 
                                                : `(${Math.round((dimData?.cardio?.effort?.recommended?.high_intensity_percentage_completed || 0) * 100)}%)`}
                                        </Interpolated> of{' '}<Wiki reference="high_intensity">high-intensity</Wiki> training
                                    </li>
                                </ul>
                            </div> 
                        </>
                    )}
                </>
            );

        case 'strength':
            return (
                <>
                    <h2>Health overview</h2>

                    <WidgetDimension
                        title="Main Strength Metrics"
                        variant="strength"
                        percentile={dimData?.strength?.measure?.percentile}
                        precision = {(dimData?.strength?.measure?.precision * 100).toFixed(0)}

                        metrics={[
                            {
                                label: dimData?.strength?.measure?.title || "Cycling FTP",
                                value: Math.round(dimData?.strength?.measure?.value)?.toString() || "220",
                                unit: dimData?.strength?.measure?.unit || "watts"
                            },
                            {
                                label: "Healthy age",
                                value: dimData?.strength?.measure?.decay_age?.toString() || "82",
                                unit: "year-old"
                            },
                            {
                                label: "Confidence",
                                value: dimData?.strength?.measure?.precision
                                    ? `${(dimData?.strength?.measure?.precision * 100).toFixed(0)}%`
                                    : "Medium"
                            }
                        ]}
                    />

                    <div className="content-section">
                        <p>
                            Your <Wiki reference="cycling_ftp">{dimData?.strength?.measure?.title || "Cycling FTP"}</Wiki> is{' '}
                            <Interpolated>{Math.round(dimData?.strength?.measure?.value)?.toString() || "220"} {dimData?.strength?.measure?.unit || "watts"}</Interpolated>,
                            placing you in the <Interpolated>{getPercentileTextPrecise(dimData?.strength?.measure?.percentile)}</Interpolated> of <Interpolated>{dimData?.details?.user_age_gender_group}</Interpolated>. 👏
                        </p>

                        <p>
                            Your <Wiki reference="cycling_ftp">Cycling FTP</Wiki> is <Wiki reference="strength_decline">projected</Wiki> to reach minimal levels by age{' '}
                            <Interpolated>{dimData?.strength?.measure?.decay_age || "75"}</Interpolated>
                            {(dimData?.strength?.measure?.decay_age || 75) >= (dimData?.details?.life_expectancy || 84) ? (
                                <>. This isn't worrying, as that's above the life expectancy for your age and gender group (<Interpolated>{dimData?.details?.life_expectancy}</Interpolated> years old).</>
                            ) : (
                                <> - out of a total life expectancy of <Interpolated>{dimData?.details?.life_expectancy}</Interpolated></>
                            )}
                        </p>

                        <p>
                            Your measure is <Interpolated>{(dimData?.strength?.measure?.precision * 100 || 75).toFixed(0)}%</Interpolated> precise.
                            You can improve it by completing a <Wiki reference="cycling_ftp">high-intensity ride</Wiki>.
                        </p>
                    </div>

                    <MeasuresLineGraph 
                        data={dimData?.strength?.measure?.past_measures}
                        color="#1e7cff"
                        graphTitle="Cycling FTP Measures"
                        unit="watts"
                    />

                    <h2>Effort</h2>
                    {dimData?.strength?.effort?.cycling_as_strength_proxy?.recommended?.nb_bars === 0 ? (
                        <span className="no-data-message">
                            No cycling workout captured this past week.
                        </span>
                    ) : (
                        <>
                            <WidgetDimension
                                title="Past Week Activity"
                                variant="strength"
                                effortBars={dimData?.strength?.effort?.cycling_as_strength_proxy?.recommended?.nb_bars || 0}
                                effortTitle="Weekly Training"
                                metrics={[
                                    {
                                        label: "Cycling time",
                                        value: dimData?.strength?.effort?.cycling_as_strength_proxy?.mins?.toString() || "0",
                                        unit: "min"
                                    },
                                    {
                                        label: "High intensity",
                                        value: dimData?.strength?.effort?.cycling_as_strength_proxy?.high_intensity?.nb_activities?.toString() || "0",
                                        unit: "workouts"
                                    }
                                ]}
                            />

                            <MinPerDay 
                                data={dimData?.strength?.effort?.cycling_as_strength_proxy?.past_week_data} 
                                color="#1e7cff" 
                                graphTitle="Cycling Training, Past Week"
                            />

                            <div className="content-section">
                                <p>In the past 7 days, you've completed:</p>
                                <ul>
                                    <li>
                                        <Interpolated>{dimData?.strength?.effort?.cycling_as_strength_proxy?.mins || 0}</Interpolated> out of the recommended 120 minutes (
                                        <Interpolated>
                                            {Math.round((dimData?.strength?.effort?.cycling_as_strength_proxy?.recommended?.cycling_percentage_completed || 0) * 100)}%
                                        </Interpolated>) of cycling
                                    </li>
                                    <li>
                                        <Interpolated>{dimData?.strength?.effort?.cycling_as_strength_proxy?.high_intensity?.nb_activities || 0}</Interpolated> out of the recommended 2 sessions 
                                        <Interpolated>
                                            {(dimData?.strength?.effort?.cycling_as_strength_proxy?.high_intensity?.nb_activities || 0) > 2
                                                ? "(over 100%)" 
                                                : `(${Math.round((dimData?.strength?.effort?.cycling_as_strength_proxy?.recommended?.high_intensity_percentage_completed || 0) * 100)}%)`}
                                        </Interpolated> of high-intensity cycling
                                    </li>
                                </ul>
                            </div>
                        </>
                    )}
                </>
            );

        case 'stability':
            return (
                <>
                    {/* <h2>Health overview</h2> */}

                    <WidgetDimension
                        title="Stability & Mobility"
                        variant="stability"
                        percentile={dimData?.stability?.measure?.percentile}
                        precision = {(dimData?.stability?.measure?.precision * 100).toFixed(0)}

                        metrics={[
                            {
                                label: "Output consistency",
                                value: dimData?.stability?.measure?.value?.toString(),
                                unit: dimData?.stability?.measure?.unit
                            },
                            {
                                label: "Healthy age",
                                value: dimData?.stability?.measure?.decay_age?.toString(),
                                unit: "year-old"
                            },
                            {
                                label: "Confidence",
                                value: `${(dimData?.stability?.measure?.precision * 100).toFixed(0)}%`
                            }
                        ]}
                    />

                    <div className="content-section">
                        <p>
                            Your <Wiki reference="output_consistency">Output Consistency</Wiki> score is{' '}
                            <Interpolated>{dimData?.stability?.measure?.value?.toString() || "1.8"}</Interpolated>,
                            placing you in the <Interpolated>{getPercentileTextPrecise(dimData?.stability?.measure?.percentile)}</Interpolated> of <Interpolated>{dimData?.details?.user_age_gender_group}</Interpolated>. 👏
                        </p>

                        <p>
                            Your stability is <Wiki reference="stability_decline">projected</Wiki> to reach minimal levels by age{' '}
                            <Interpolated>{dimData?.stability?.measure?.decay_age || "79"}</Interpolated>
                            {(dimData?.stability?.measure?.decay_age || 79) >= (dimData?.details?.life_expectancy || 84) ? (
                                <>. This isn't worrying, as that's above the life expectancy for your age and gender group (<Interpolated>{dimData?.details?.life_expectancy}</Interpolated> years old).</>
                            ) : (
                                <> - out of a total life expectancy of <Interpolated>{dimData?.details?.life_expectancy}</Interpolated></>
                            )}
                        </p>

                        <p>
                            {(dimData?.stability?.measure?.precision || 0) >= 0.90 ? (
                                <>
                                    Your measure is quite precise, at{" "}
                                    <Interpolated>{(dimData?.stability?.measure?.precision * 100).toFixed(0)}%</Interpolated>.
                                    It was calculated using your most consistent runs and rides.
                                </>
                            ) : (
                                <>
                                    Your measure is {(dimData?.stability?.measure?.precision || 0) >= 0.80 ? 'fairly' : ''} <Interpolated>{(dimData?.stability?.measure?.precision * 100 || 75).toFixed(0)}%</Interpolated> precise.
                                    You can improve it by completing more steady-state workouts on varied terrain.
                                </>
                            )}

                            {dimData?.stability?.measure?.activity_id && (
                                <>
                                    {" "}It used the training session{' '}
                                    <Interpolated>{dimData?.stability?.measure?.activity_name}</Interpolated> on{' '}
                                    <Interpolated>
                                        {new Date(dimData?.stability?.measure?.activity_date).toLocaleDateString()}
                                    </Interpolated>:{" "}
                                    <a
                                        href={`https://www.strava.com/activities/${dimData?.stability?.measure?.activity_id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="strava-link"
                                    >
                                        View on Strava
                                    </a>.
                                </>
                            )}
                        </p>

                        <p>
                            <Wiki reference="stability_score">Stability & Mobility</Wiki> is currently measured through output consistency 
                            in running and cycling. While this provides valuable insights, it's worth noting that a complete stability 
                            assessment would include additional tests like balance and movement pattern analysis.
                        </p>
                    </div>
                </>
            );

        default:
            return null;
    }
};

const Dimension = ({ dimension, onClose, dimData }) => {
    const dimensionContent = content[dimension];

    if (!dimensionContent || !dimData) return null;

    return (
        <div className={`dimension-container ${dimension}`}>
            <div className="dimension-header">
                <div className="title-wrapper" onClick={onClose}>
                    <div className="category-icon">
                        <FontAwesomeIcon icon={faChevronLeft} className="back-chevron" />
                    </div>
                    <h1>{dimensionContent.title}</h1>
                </div>
            </div>

            <div className="dimension-content">
                {getDimensionContent(dimension, dimData)}
            </div>
        </div>
    );
};

export default Dimension;
