import React from 'react';
import './DimensionHelpers.scss';
import infoIcon from '../../images/info_tertiary.svg';

const WIKI_DEFINITIONS = {
    VO2_max: {
        term: "VO2 Max",
        definition: <>
            <p>
                <b>
                    VO2 max measures how much oxygen your body can use during intense exercise, expressed in milliliters per kilogram of body weight per minute (ml/kg/min). It's considered the gold standard for measuring cardiorespiratory fitness.
                </b>
            </p>
            <p>
                Think of it as your body's engine capacity - the higher your VO2 max, the more oxygen your body can process and turn into energy. This makes it one of the most reliable indicators of overall cardio health and longevity.
            </p>
            <p>
                The impact on longevity is significant - someone with a high VO2 max of 45 ml/kg/min at age 50 can expect to live 6-8 years longer than someone with a low VO2 max of 25 ml/kg/min.
            </p>
            <p>
                Research shows that VO2 max is strongly linked to health outcomes - for example, people with values below 18 ml/kg/min often require assistance with daily activities, while higher values are associated with better health and increased life expectancy.
            </p>
        </>
    },
    longevity_score: {
        term: "Longevity Score",
        definition: <>

            <p>

                <b>
                    Your Longevity Score represents the proportion of your life projected to
                    be spent in good health.{" "}
                </b>

            </p>
            <p>

                To perform everyday activities (such as climbing two flights of
                stairs or lifting a suitcase), you need adequate cardiorespiratory fitness, strength and stability.{" "}
            </p>

            <p>
                So by applying discount rates to your current cardio, strength and stability metrics, we obtain the age at which each of these metrics will drop below acceptable levels. 
            </p>

            <p>
                
                Longevity Score is calculated by dividing this age by the life
                expectancy for your age and gender group.
            </p>


        </>
    },
    cardio_decline: {
        term: "VO2 max decline projection",
        definition: <>
            <p>
                <b>
                    This projection estimates when your VO2 max might decline below functional thresholds (20 ml/kg/min), based on current performance and typical age-related changes.
                </b>
            </p>
            <p>
                VO2 max typically declines by 5% per decade before age 50, accelerating to 10% per decade afterwards. This means someone with a VO2 max of 42 ml/kg/min at age 35 would see it drop to about 40 by age 45, then 36 by age 55, and 29 by age 65.
            </p>
            <p>
                The projection helps understand how long you're likely to maintain good cardiorespiratory fitness above the critical threshold of 20 ml/kg/min, below which daily activities become challenging.
            </p>
        </>
    },
    strength_decline: {
        term: "Strength decline projection",
        definition: <>
            <p>
                <b>
                    This projection estimates when your strength metrics might decline below functional thresholds, based on current performance and typical age-related changes.
                </b>
            </p>
            <p>
                Strength typically declines by 3-5% per decade before age 50, then accelerates to 12-15% per decade afterwards. For cycling power, this translates to roughly a 8% decline per decade before 50, and 12% after.
            </p>
            <p>
                The projection helps understand how long you're likely to maintain adequate strength for daily activities, with the critical threshold being around 40% of your peak strength.
            </p>
        </>
    },
    stability_decline: {
        term: "Stability decline projection",
        definition: <>
            <p>
                <b>
                    This projection estimates when your movement consistency might decline below functional thresholds, based on current performance and typical age-related changes.
                </b>
            </p>
            <p>
                Movement consistency typically declines more slowly than pure strength or cardio capacity, at about 0.8% per year (8% per decade). Good movement patterns, once established, tend to persist with regular practice.
            </p>
            <p>
                The projection helps understand how long you're likely to maintain good movement control - a crucial factor for maintaining independence and preventing falls in later life.
            </p>
        </>
    },
    cooper_test: {
        term: "Cooper test",
        definition: <>
            <p>
                <b>
                    The Cooper test is a simple 12-minute running test that measures your cardiovascular fitness. 
                </b>
            </p>
            <p>
                You run as far as you can in 12 minutes, and the distance covered is used to calculate your VO2 max (maximum oxygen uptake capacity).
            </p> 
            <p>
                For example, running 2800m in 12 minutes indicates a VO2 max of around 51 ml/kg/min - placing you in the top 6% for men aged 30-40 and top 1% for women in the same age group.
            </p>
            <p>
                It's one of the most reliable field tests for assessing aerobic fitness. Simply record a 12-minute run with Strava, and your VO2 max estimate will automatically update with increased confidence.
            </p>
        </>
    },
    zone_2: {
        term: "Zone 2 Cardio",
        definition: <>
            <p>
                <b>
                    Zone 2 cardio is moderate-intensity exercise, typically at 60-70% of your maximum heart rate. A good way to identify Zone 2 is when you can maintain a conversation while working out.
                </b>
            </p>
            <p>
                This type of training is crucial for building endurance and promoting cardiovascular health, with experts recommending 150-200 minutes per week.
            </p>

            <p>
                For a healthy 35-year-old with a maximum heart rate of 185 bpm (220 minus age), Zone 2 would be between 111 and 130 beats per minute.
            </p>
            <p>
                You can achieve Zone 2 through everyday activities like brisk walking, light jogging, cycling, or hiking - making it easy to incorporate into your daily routine without needing dedicated workout sessions.
            </p>
        </>
    },
    high_intensity: {
        term: "High-Intensity Training",
        definition: <>
            <p>
                <b>
                    High-intensity training involves short bursts of maximum effort exercise that push your heart rate to near-maximum levels. These sessions are crucial for improving cardiovascular fitness and are recommended 1-2 times per week alongside regular Zone 2 cardio.
                </b>
            </p>
            <p>
                This type of training is highly efficient at building cardiovascular capacity, with sessions typically lasting 20-30 minutes. The intense nature of these workouts triggers unique adaptations in your body that complement the benefits of steady-state cardio.
            </p>
            <p>
                Research shows that incorporating high-intensity sessions into your routine can significantly improve VO2 max and overall cardiovascular health, while requiring relatively little time commitment compared to other forms of exercise.
            </p>
            {/* <p>
                These sessions can be performed through various activities like cycling, running, or rowing - the key is reaching near-maximum effort during the intense intervals while allowing adequate recovery between efforts.
            </p> */}
        </>
    },
    cycling_ftp: {
        term: "Cycling FTP",
        definition: <>
            <p>
                <b>
                    FTP (Functional Threshold Power) measures the highest average power you can sustain for one hour on a bike, expressed in watts. It's widely considered the benchmark metric for measuring cycling performance and aerobic capacity.
                </b>
            </p>
            <p>
                Think of it as your sustainable power output - the higher your FTP, the more power you can generate over long periods. This makes it one of the most reliable indicators of cycling fitness and endurance capacity.
            </p>
            <p>
                To improve the precision of your FTP estimate:
                <ul>
                    <li>Use a power meter if possible (adds 15% precision)</li>
                    <li>Ride for 48-72 minutes (adds 10% precision)</li>
                    <li>Maintain a heart rate between 85-90% of your max (adds 10% precision)</li>
                </ul>
            </p>
        </>
    },
    strength_training: {
        term: "Strength Training",
        definition: <>
            <p>
                <b>
                    Strength training is resistance-based exercise that builds muscle and increases strength. For optimal results, each muscle group should be trained at least twice per week, with 6-10 working sets per muscle group.
                </b>
            </p>
            <p>
                This training frequency allows adequate stimulus for strength gains while providing sufficient recovery time between sessions. 
                
                {/* The exact frequency can be adjusted based on individual goals and recovery capacity. */}
            </p>
            <p>
                Regular strength training becomes increasingly important with age, as it helps offset the natural decline in muscle mass and strength. 
                
                {/* This preservation of muscle mass is crucial for maintaining functional fitness and independence throughout life. */}
            </p>
        </>
    },
    output_consistency: {
        term: "Output Consistency",
        definition: <>
            <p>
                <b>
                    Output Consistency measures how well you maintain steady effort during exercise, particularly on varied terrain. Scored from 0 to 3, it reflects your ability to pace yourself efficiently across different conditions.
                </b>
            </p>
            <p>
                Think of it like cruise control in a car - high consistency means maintaining the same relative effort whether going uphill, downhill, or on flat ground. This efficiency is a key indicator of good movement control and stability.
            </p>
            <p>
                The score combines data from both running and cycling, with extra credit given for maintaining consistency on challenging terrain. For example, keeping steady effort on hills scores higher than the same consistency on flat ground.
            </p>
        </>
    },
    stability_projection: {
        term: "Stability Projection",
        definition: <>
            <p>
                <b>
                    This projection estimates when your movement consistency might decline below functional thresholds, based on current performance and typical age-related changes.
                </b>
            </p>
            <p>
                Movement consistency typically declines more slowly than pure strength or cardio capacity, at about 0.8% per year. Good movement patterns, once established, tend to persist with regular practice.
            </p>
            <p>
                The projection helps understand how long you're likely to maintain good movement control - a crucial factor for maintaining independence and preventing falls in later life.
            </p>
        </>
    },
    stability_score: {
        term: "Stability & Mobility",
        definition: <>
            <p>
                <b>
                    Stability & Mobility represents your body's ability to maintain control and efficiency during movement. 
                </b>
            </p>

            <p>
            While our current measurement focuses on output consistency, true stability encompasses broader aspects of movement quality.
            </p>
            <p>
                Dr. Peter Attia's definition is more holistic, focusing on:
                <ul>
                    <li>Force transmission efficiency</li>
                    <li>Joint protection and proper load distribution</li>
                    <li>Foundational movement patterns</li>
                    <li>Neuromuscular control systems</li>
                </ul>
            </p>
        </>
    },
    good_years_left: {
        term: "How we calculate 'years in shape'",
        definition: <>
            <p>
                <b>
                    We look at three key markers to predict how long you'll maintain good fitness: cardio fitness (VO₂ max), strength, and movement quality.
                </b>
            </p>
            <p>
                We know how these abilities naturally decline with age and can project when they might drop too low for daily activities like climbing stairs or carrying groceries.
            </p>
            <p>
                For example, for a typical 40 y-o man with a VO₂ max of 35 ml/kg/min, applying the standard decline rates of 5-15% per decade depending on age, his VO₂ max will reach critical levels at age 75.
            </p>
            <p>
                Like a chain, your projection is limited by your weakest metric. If cardio would stay strong until 82, but strength might become limiting at 75, we'll use 75 as your projection.
            </p>
        </>
    }
    // ... other definitions can be added here
};

export const Interpolated = ({ children, notConnected }) => (
    <span className={`interpolated-data ${notConnected ? 'not-connected' : ''}`}>
        {children}
    </span>
);

export const Wiki = ({ children, reference }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const definition = WIKI_DEFINITIONS[reference];

    return (
        <>
            <span
                className="wiki-term"
                onClick={() => setIsOpen(true)}
            >
                {children}
            </span>

            {isOpen && (
                <>
                    <div className="wiki-overlay" onClick={() => setIsOpen(false)} />
                    <div className="wiki-popup">
                        <button className="close-button" onClick={() => setIsOpen(false)}>×</button>
                        <h2>{definition?.term || reference}</h2>
                        <div>{definition?.definition || "Definition not found"}</div>
                    </div>
                </>
            )}
        </>
    );
};

export const WikiInfo = ({ reference }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const definition = WIKI_DEFINITIONS[reference];

    return (
        <>
            <img
                src={infoIcon}
                alt="Info"
                className="info-icon"
                onClick={() => setIsOpen(true)}
            />

            {isOpen && (
                <div className="wiki-wrapper">
                    <div className="wiki-overlay" onClick={() => setIsOpen(false)} />
                    <div className="wiki-popup">
                        <button className="close-button" onClick={() => setIsOpen(false)}>×</button>
                        <h2>{definition?.term || reference}</h2>
                        <p>{definition?.definition}</p>
                    </div>
                </div>
            )}
        </>
    );
};

export const WikiFreeForm = ({ children, reference }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const definition = WIKI_DEFINITIONS[reference];

    return (
        <>
            <span
                className="wiki-wrapper"
                onClick={() => setIsOpen(true)}
            >
                {children}
            </span>

            {isOpen && (
                <>
                    <div className="wiki-overlay" onClick={() => setIsOpen(false)} />
                    <div className="wiki-popup">
                        <button className="close-button" onClick={() => setIsOpen(false)}>×</button>
                        <h2>{definition?.term || reference}</h2>
                        <div>{definition?.definition || "Definition not found"}</div>
                    </div>
                </>
            )}
        </>
    );
};
