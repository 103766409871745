// Import helper functions
import { getLatestBatchFromMetric, getMainMeasurementFromLatestBatch } from './metrics_helper';
import { getPercentileColor } from './profile_helper';
import { profileMetrics } from './profile_data';
import { getPercentile, getGoodYearsLeft } from '../components/v4_components/calc/supporting_functions';


export function createDimDataForProfilev3(userData) {
    // console.log('userData', JSON.stringify(userData, null, 2))
    function getPrecisionTag(precision) {
        if (precision > 0.9) return "High";
        if (precision > 0.8) return "Good";
        if (precision > 0.5) return "Average";
        return "Low";
    }

    // Get life expectancy and log it
    const lifeScoreBatch = getLatestBatchFromMetric(userData.metrics.life_score);
    const lifeScoreData = userData.metrics.life_score[lifeScoreBatch];
    const lifeExpectancy = lifeScoreData?.measures?.main_measure?.optional_details?.life_expectancy || 75;
    //   console.log('Life Expectancy:', lifeExpectancy);

    // Process life score
    const lifeScoreMainMeasure = lifeScoreData?.measures?.main_measure;
    const lifeScore = {
        score: Number((lifeScoreMainMeasure?.value / 100 || 0).toFixed(2)),
        color: getPercentileColor(lifeScoreMainMeasure?.percentile),
        percentile: lifeScoreMainMeasure?.percentile,
        precision: lifeScoreMainMeasure?.precision ? Number(lifeScoreMainMeasure.precision.toFixed(2)) : null
    };

    // Process dimensions
    const dimensionsMap = {
        cardio: {
            metricCode: 'v02_max',
            title: 'Cardio score'
        },
        strength: {
            metricCode: 'ftp',
            title: 'Strength score'
        },
        stability: {
            metricCode: 'output_consistency',
            title: 'Output consistency score'
        }
    };

    const workoutInsights = userData.workout_insights;
    console.log('stringifyied userData:', JSON.stringify(userData, null, 2));
    
    console.log('workoutInsights:', workoutInsights);
    console.log('typeof workoutInsights:', typeof workoutInsights);
    
    const last7Days = workoutInsights 
        ? Object.keys(workoutInsights)
            .sort((a, b) => new Date(b) - new Date(a))
            .slice(0, 7)
        : [];

    const totalZone2Mins = last7Days.reduce((sum, date) => sum + (workoutInsights[date].zone_2_mins || 0), 0);
    const totalCyclingMins = last7Days.reduce((sum, date) => sum + (workoutInsights[date].cycling_minutes || 0), 0);

    const dimensions = {};

    Object.entries(dimensionsMap).forEach(([dimName, { metricCode, title }]) => {
        const metricData = userData.metrics[metricCode];
        const latestBatch = getLatestBatchFromMetric(metricData);
        const mainMeasure = getMainMeasurementFromLatestBatch(metricData);

        // Add collection of past measures
        const pastMeasures = [];
        if (metricData && latestBatch && metricData[latestBatch]?.measures) {
            const measures = metricData[latestBatch].measures;
            Object.entries(measures).forEach(([key, measure]) => {
                if (measure?.value && measure?.timestamp) {
                    // Create a proper date object from the timestamp
                    const date = new Date(measure.timestamp);
                    pastMeasures.push({
                        date: date.toISOString().split('T')[0], // Store as YYYY-MM-DD
                        value: Number(measure.value.toFixed(1))
                    });
                }
            });
            // Sort by date - oldest to newest
            pastMeasures.sort((a, b) => new Date(a.date) - new Date(b.date));
        }

        const metricInfo = profileMetrics.find(m => m.metric_code === metricCode);

        dimensions[dimName] = {
            measure: {
                title: metricInfo?.real_name || metricCode,
                value: mainMeasure?.value 
                    ? (metricCode === 'output_consistency' 
                        ? Number(mainMeasure.value.toFixed(1)) 
                        : Math.round(mainMeasure.value))
                    : null,
                unit: metricInfo?.unit || '',
                percentile: mainMeasure?.percentile,
                precision: mainMeasure?.precision ? Number(mainMeasure.precision.toFixed(2)) : null,
                decay_age: mainMeasure?.decay_age || null,
                activity_id: mainMeasure?.workout_id,
                activity_name: mainMeasure?.workout_name,
                activity_date: mainMeasure?.timestamp,
                past_measures: pastMeasures
            },
            effort: calculateEffortMetrics(workoutInsights, dimName)
        };
    });
    // console.log('dimensions', JSON.stringify(dimensions, null, 2))

    // Create age gender group string
    const ageGroup = lifeScoreData?.measures?.main_measure?.optional_details?.age_group || "30-39";
    const gender = lifeScoreData?.measures?.main_measure?.optional_details?.gender === "M" ? "men" : "women";
    const [ageStart, ageEnd] = ageGroup.split("-");
        // const user_age_gender_group = `${gender} between ${ageStart} and ${ageEnd} years old`;

    const user_age_gender_group = `${gender} aged ${ageGroup}`;

    console.log('Dimensions with precision:', JSON.stringify({
        cardio: dimensions.cardio?.measure?.precision,
        strength: dimensions.strength?.measure?.precision,
        stability: dimensions.stability?.measure?.precision
    }, null, 2));

    return {
        life_score: lifeScore,
        details: {
            user_age_gender_group,
            life_expectancy: Math.round(lifeExpectancy)
        },
        ...dimensions
    };
}

function calculateEffortMetrics(workoutInsights, dimName) {
    if (!workoutInsights) {
        return {};
    }

    if (dimName === 'cardio') {
        if (!workoutInsights.cardio) {
            return {
                scope: {
                    start_date: new Date().toISOString().split('T')[0],
                    end_date: new Date().toISOString().split('T')[0]
                },
                zone_2: { mins: 0, nb_activities: 0, activities: [] },
                high_intensity: { nb_activities: 0, activities: [] },
                recommended: {
                    zone_2_percentage_completed: 0,
                    high_intensity_percentage_completed: 0,
                    zone_2_completed: false,
                    high_intensity_completed: false,
                    both_completed: false,
                    nb_bars: 0
                },
                past_week_data: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => ({ day, value: 0 }))
            };
        }

        const last7Days = Object.keys(workoutInsights.cardio)
            .sort((a, b) => new Date(b) - new Date(a))
            .slice(0, 7);

        // Add past week data for graph
        const past_week_data = last7Days.map(date => {
            const dayData = workoutInsights.cardio[date].zone_2;
            return {
                date: date,
                value: dayData.minutes || 0
            };
        }).reverse();

        // Don't fill with arbitrary days anymore, instead keep only the actual dates
        const filledData = past_week_data;

        const endDate = last7Days[0] || new Date().toISOString().split('T')[0];
        const startDate = last7Days[last7Days.length - 1] || endDate;

        const zone2Data = last7Days.reduce((acc, date) => {
            const dayData = workoutInsights.cardio[date].zone_2;
            return {
                mins: acc.mins + (dayData.minutes || 0),
                activities: [...acc.activities, ...dayData.activities],
                nb_activities: acc.nb_activities + (dayData.activities?.length || 0)
            };
        }, { mins: 0, activities: [], nb_activities: 0 });

        const highIntensityData = last7Days.reduce((acc, date) => {
            const dayData = workoutInsights.cardio[date].high_intensity;
            return {
                mins: acc.mins + (dayData.minutes || 0),
                nb_activities: acc.nb_activities + (dayData.activities?.length || 0),
                activities: [...acc.activities, ...dayData.activities]
            };
        }, { mins: 0, nb_activities: 0, activities: [] });

        const zone2PercentageCompleted = Math.min(zone2Data.mins / 180, 1);
        const highIntensityPercentageCompleted = Math.min(highIntensityData.nb_activities / 2, 1);

        const zone2Bars = Math.min(Math.round(zone2Data.mins / 60), 3);
        const highIntensityBars = Math.floor(highIntensityPercentageCompleted * 2);

        return {
            scope: {
                start_date: startDate,
                end_date: endDate
            },
            zone_2: {
                mins: zone2Data.mins,
                nb_activities: zone2Data.activities.length,
                activities: zone2Data.activities
            },
            high_intensity: {
                nb_activities: highIntensityData.nb_activities,
                activities: highIntensityData.activities
            },
            recommended: {
                zone_2_percentage_completed: zone2PercentageCompleted,
                high_intensity_percentage_completed: highIntensityPercentageCompleted,
                zone_2_completed: zone2PercentageCompleted >= 1,
                high_intensity_completed: highIntensityPercentageCompleted >= 1,
                both_completed: zone2PercentageCompleted >= 1 && highIntensityPercentageCompleted >= 1,
                nb_bars: zone2Bars + highIntensityBars
            },
            past_week_data: filledData
        };
    } else if (dimName === 'strength') {
        if (!workoutInsights.strength) {
            return {
                scope: {
                    start_date: new Date().toISOString().split('T')[0],
                    end_date: new Date().toISOString().split('T')[0]
                },
                cycling_as_strength_proxy: {
                    mins: 0,
                    nb_activities: 0,
                    high_intensity: { nb_activities: 0, activities: [] },
                    recommended: {
                        cycling_percentage_completed: 0,
                        high_intensity_percentage_completed: 0,
                        cycling_completed: false,
                        high_intensity_completed: false,
                        both_completed: false,
                        nb_bars: 0
                    },
                    past_week_data: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => ({ day, value: 0 }))
                }
            };
        }

        const last7Days = Object.keys(workoutInsights.strength)
            .sort((a, b) => new Date(b) - new Date(a))
            .slice(0, 7);

        // Add past week data for graph
        const past_week_data = last7Days.map(date => {
            const dayData = workoutInsights.strength[date].cycling;
            return {
                date: date,
                value: dayData.minutes || 0
            };
        }).reverse();

        // Don't fill with arbitrary days anymore, instead keep only the actual dates
        const filledData = past_week_data;

        const endDate = last7Days[0] || new Date().toISOString().split('T')[0];
        const startDate = last7Days[last7Days.length - 1] || endDate;

        const cyclingData = last7Days.reduce((acc, date) => {
            const dayData = workoutInsights.strength[date].cycling;
            return {
                mins: acc.mins + (dayData.minutes || 0),
                activities: [...acc.activities, ...dayData.activities],
                nb_activities: acc.nb_activities + (dayData.activities?.length || 0)
            };
        }, { mins: 0, activities: [], nb_activities: 0 });

        const highIntensityData = last7Days.reduce((acc, date) => {
            const dayData = workoutInsights.strength[date].high_intensity_cycling;
            return {
                nb_activities: acc.nb_activities + (dayData.activities?.length || 0),
                activities: [...acc.activities, ...dayData.activities]
            };
        }, { nb_activities: 0, activities: [] });

        const cyclingPercentageCompleted = Math.min(cyclingData.mins / 120, 1);
        const highIntensityPercentageCompleted = Math.min(highIntensityData.nb_activities / 2, 1);

        const cyclingBars = Math.min(Math.round(cyclingData.mins / 40), 3);
        const highIntensityBars = Math.min(Math.floor(highIntensityData.nb_activities), 2);

        return {
            scope: {
                start_date: startDate,
                end_date: endDate
            },
            cycling_as_strength_proxy: {
                mins: cyclingData.mins,
                nb_activities: cyclingData.activities.length,
                high_intensity: {
                    nb_activities: highIntensityData.activities.length,
                    activities: highIntensityData.activities
                },
                recommended: {
                    cycling_percentage_completed: cyclingPercentageCompleted,
                    high_intensity_percentage_completed: highIntensityPercentageCompleted,
                    cycling_completed: cyclingPercentageCompleted >= 1,
                    high_intensity_completed: highIntensityPercentageCompleted >= 1,
                    both_completed: cyclingPercentageCompleted >= 1 && highIntensityPercentageCompleted >= 1,
                    nb_bars: cyclingBars + highIntensityBars
                },
                past_week_data: filledData
            }
        };
    }

    return {};
}

export function createDimDataForProfilev4(userData) {
    // console.log('stringifyied userData:', JSON.stringify(userData, null, 2));

    // Get basic user data - updated to use user_info
    const age = userData.user_info.core.age;
    const gender = userData.user_info.core.gender === "M" ? "male" : "female";

    // Get latest metrics
    const metrics = {
        vo2_max: {
            value: userData.metrics.v02_max[getLatestBatchFromMetric(userData.metrics.v02_max)]?.measures?.main_measure?.value
        },
        ftp_cycling: {
            value: userData.metrics.ftp[getLatestBatchFromMetric(userData.metrics.ftp)]?.measures?.main_measure?.value
        },
        output_consistency: {
            value: userData.metrics.output_consistency[getLatestBatchFromMetric(userData.metrics.output_consistency)]?.measures?.main_measure?.value
        }
    };

    // Calculate good years left
    const yearsProjection = getGoodYearsLeft({
        age,
        gender,
        metrics
    });

    // Calculate percentiles for each dimension
    const percentiles = {
        cardio: getPercentile({
            gender,
            age,
            metric_code: 'vo2_max',
            metric_value: metrics.vo2_max.value
        }),
        strength: getPercentile({
            gender,
            age,
            metric_code: 'ftp_cycling',
            metric_value: metrics.ftp_cycling.value
        }),
        stability: getPercentile({
            gender,
            age,
            metric_code: 'output_consistency',
            metric_value: metrics.output_consistency.value
        })
    };

    return {
        years: {
            in_good_health: age + Math.round(yearsProjection.goodYearsLeft),
            left: Math.round(yearsProjection.goodYearsLeft),
            potential_gain: 5
        },
        percentiles
    };
}
