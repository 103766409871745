import './Indicator.scss';
import { getPercentileTextPrecise } from './PercentileTag';

export const EffortIndicator = ({ title, filledBars, variant }) => {
  const numFilledBars = Math.min(Math.round(filledBars), 5);
  
  return (
    <div className={`effort-indicator-widget`}>
      <div className={`effort-indicator ${variant || ''}`}>
        {[...Array(5)].map((_, index) => (
          <div 
            key={index} 
            className={`effort-bar ${index < numFilledBars ? 'filled' : ''}`}
          />
        ))}
      </div>

      <div className="metric">
        <div className="metric-title">{title}</div>
        <div className="metric-subtitle">
          {title === "Movement Consistency" ? "All time" : "Past 7 days"}
        </div>
      </div>
    </div>
  );
};

export const ConfidenceIndicator = ({ precision }) => {
  let status;
  let className;
  // console.log('Raw precision:', precision);
  
  // Convert decimal to percentage
  const precisionPercentage = precision * 100;
  // console.log('Precision percentage:', precisionPercentage);
  
  if (precisionPercentage >= 90) {
    status = 'High';
    className = 'high';
  } else if (precisionPercentage < 50) {
    status = 'Low';
    className = 'low';
  } else {
    status = 'Medium';
    className = 'medium';
  }
  
  return (
    <div className={`confidence-indicator ${className}`}>
      {status}
    </div>
  );
};

export const PercentileBars = ({ percentile, variant }) => {
  // Convert percentile to number of bars (0-5 scale)
  console.log('Input percentile:', percentile);
  const numFilledBars = Math.ceil(percentile * 5);
  console.log('Calculated filled bars:', numFilledBars);
  
  return (
    <div className="percentile-bars-widget">
      <div className="percentile-text">
        {getPercentileTextPrecise(percentile)}
      </div>
      <div className={`effort-indicator ${variant || ''}`}>
        {[...Array(5)].map((_, index) => (
          <div 
            key={index} 
            className={`effort-bar ${index < numFilledBars ? 'filled' : ''}`}
          />
        ))}
      </div>
    </div>
  );
};

// export default EffortIndicator;
